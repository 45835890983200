<template>
  <header
    class="payment-detail-header tw-text-center tw-flex tw-items-center tw-justify-between tw-px-3 tw-shadow-md tw-z-10 tw-h-10"
  >
    <div />
    <div
      v-if="getCurrentPayment"
      v-text="$t('billing.payment_title', {
        ref: getCurrentPayment.reference
      })"
      data-test="title"
    />
    <payment-detail-header-close-button
      :to="to"
      data-test="close"
    />
  </header>
</template>

<script>
  import { mapGetters } from 'vuex'

  import PaymentDetailHeaderCloseButton from './_subs/PaymentDetailHeaderCloseButton/index.vue'

  /**
   * @module component - PaymentDetailHeader
   * @param {object} to
   */
  export default {
    name: 'PaymentDetailHeader',
    props: {
      to: {
        type: Object,
        required: true
      }
    },
    components: {
      PaymentDetailHeaderCloseButton
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentPayment'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .payment-detail-header {
    flex: 0 0 2.5rem;
  }

</style>
