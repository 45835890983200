<template>
  <div
    v-if="invoices"
    class="invoice-detail-invoices"
  >
    <div v-if="!$wait.is('loading invoice billing')">
      <pricing-invoices-title
        v-if="getInvoices.length > 0"
        :count="getInvoices.length"
      />
      <pricing-invoice
        v-for="invoice in getInvoices"
        :key="invoice.uuid"
        :invoice="invoice"
        :currency="invoice.currency"
      />
    </div>
    <ctk-loading-layer v-else>
      {{ $t('billing.loading_invoices') }}
    </ctk-loading-layer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkLoadingLayer from '@/components/CtkLoadingLayer'
  import PricingInvoice from '@/components/CtkPricing/_subs/PricingInvoice'
  import PricingInvoicesTitle from '@/components/CtkPricing/_subs/PricingInvoicesTitle'

  /**
   * @module component - invoiceDetailInvoices
   */
  export default {
    name: 'InvoiceDetailInvoices',
    components: {
      CtkLoadingLayer,
      PricingInvoice,
      PricingInvoicesTitle
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('billing', [
        'getCurrentInvoice'
      ]),
      invoices () {
        return this.isUserShipper
          ? this.getCurrentInvoice.shipment.billing.invoices
          : this.getCurrentInvoice.mission.billing.invoices
      },
      getInvoices () {
        return this.invoices
          .filter((invoice) => invoice.uuid !== this.getCurrentInvoice.uuid)
      }
    }
  }
</script>
