<template>
  <div class="ctk-empty-state tw-flex tw-flex-1 tw-w-full tw-h-full">
    <div
      :class="{
        'sidebar': sidebar
      }"
      class="icono-bg tw-flex-1 tw-flex tw-items-center tw-justify-center tw-w-full"
    >
      <div class="tw-text-center content">
        <div class="tw-flex tw-items-center tw-justify-center tw-flex-col">
          <h4 class="tw-font-normal">
            {{ title }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CtkEmptyState',
    props: {
      title: {
        type: String,
        default: null
      },
      sidebar: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-empty-state {
    &,
    .icono-bg {
      position: relative;
    }

    &.sidebar::before { /* stylelint-disable-line */
      .icono-bg {
        width: 100% !important;
        background-image: url('~@/assets/img/illustration_right.svg');
      }
    }

    .icono-bg::before {
      content: '';
      height: 300px;
      background-image: url('~@/assets/img/illustrations/shipments.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;
      opacity: 0.8;
      width: 60%;
      margin: 0 auto;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
    }

    h4 {
      margin-bottom: 128px;
      font-size: 18px;
      color: $info;
    }

    .content {
      z-index: 2;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .icono-bg::before {
        width: 100%;
      }
    }
  }

</style>
