<template>
  <div class="invoice-detail-header tw-flex tw-items-center tw-justify-between flex-fixed">
    <div class="invoice-detail-header__references">
      <template
        v-if="invoice"
      >
        <i18n
          data-test="invoice-reference"
          tag="div"
          path="billing.titles.invoice_ref"
        >
          <template #ref>
            <span
              v-text="invoice.reference"
              class="invoice-detail-header__references__ref"
            />
          </template>
        </i18n>
        <template
          v-if="!!invoice.shipment"
        >
          <i18n
            data-test="shipment-reference"
            tag="div"
            path="billing.titles.shipment_ref"
          >
            <template #ref>
              <span
                v-text="invoice.shipment.reference"
                class="invoice-detail-header__references__ref"
              />
            </template>
          </i18n>
          <i18n
            v-if="!!invoice.shipment.shipper_reference"
            data-test="shipment-shipper-reference"
            tag="div"
            path="billing.titles.shipment_shipper_ref"
          >
            <template #ref>
              <span
                v-text="invoice.shipment.shipper_reference"
                class="invoice-detail-header__references__ref"
              />
            </template>
          </i18n>
        </template>
        <template
          v-if="!!invoice.mission"
        >
          <i18n
            data-test="mission-reference"
            tag="div"
            path="billing.titles.mission_ref"
          >
            <template #ref>
              <span
                v-text="invoice.mission.reference"
                class="invoice-detail-header__references__ref"
              />
            </template>
          </i18n>
        </template>
      </template>
    </div>
    <close-button />
  </div>
</template>

<script>
  import CloseButton from './_subs/CloseButton'

  /**
   * @module component - InvoiceDetailHeade
   * @param {Object} invoice
   */
  export default {
    name: 'InvoiceDetailHeader',
    components: {
      CloseButton
    },
    props: {
      invoice: {
        type: Object,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>

  .invoice-detail-header {
    padding: 8px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
    min-height: 40px;

    &__references {
      color: $secondary-text;

      &__ref {
        color: $primary-text;
      }
    }
  }

</style>
