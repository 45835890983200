<template>
  <payment-table
    v-bind="$attrs"
    :items="items"
    :columns="columns"
    @infinite-scroll="$emit('infinite-scroll')"
  >
    <template
      slot-scope="{ item }"
    >
      <payment-item
        :key="item.uuid"
        :data="item"
      />
    </template>
  </payment-table>
</template>

<script>
  import PaymentTable from '@/views/Common/Billing/_subs/Payments/components/PaymentTable/index.vue'
  import PaymentItem from './_subs/PaymentItem.vue'

  /**
   * Shows a list of the payments through a billing table
   * @module component - CarrierPaymentTable
   * @param {Array<any>} items
   */
  export default {
    name: 'CarrierPaymentTable',
    components: {
      PaymentItem,
      PaymentTable
    },
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        columns: {
          reference: 'billing.payments.reference',
          issue_date: 'billing.payments.issue_date',
          amount: 'billing.payments.amount'
        }
      }
    }
  }
</script>
