<template>
  <div
    class="tw-flex invoice-item-inline tw-rounded tw-w-full tw-items-center"
  >
    <!-- Select checkbox -->
    <b-form-checkbox
      v-if="selectable"
      :checked="checked"
      class="invoice-checkbox tw-ml-3"
      data-test="checkbox"
      @change="$emit('update:checked', !checked)"
    />

    <!-- Alert icon -->
    <div class="flex-fixed invoice-item-column invoice-alert-column">
      <invoice-item-alert
        v-if="hasError"
        :invoice="data"
        :has-tooltip="hasErrorTooltip"
        data-test="alert"
      />
    </div>

    <!-- Due date -->
    <div
      class="tw-flex-1 invoice-item-column due-date-column tw-truncate"
      data-test="due-date"
    >
      <span class="value tw-text-left tw-flex tw-items-center tw-truncate tw-ml-2">
        <template
          v-if="data.type && data.type.key === 'AFC'"
        >
          <UiBadge
            color="purple"
            data-test="credit"
          >
            {{ $t(data.status === 'paid'
              ? 'billing.labels.credit_paid'
              : 'billing.labels.credit_available', {
              date: dueDate
            }) }}
          </UiBadge>
        </template>
        <template
          v-else
        >
          <UiBadge
            v-if="data.state === 'paid'"
            color="teal"
            data-test="paid"
          >
            {{ $t(data.payment && data.payment.method === 'credit_card'
              ? 'billing.labels.paid_cc'
              : 'billing.labels.paid', {
              date: dueDate
            }) }}
          </UiBadge>
          <UiBadge
            v-else-if="hasError"
            color="orange"
            data-test="late"
          >
            {{ dueDate }}
          </UiBadge>
          <span
            v-else
            data-test="none"
          >
            {{ dueDate }}
          </span>
        </template>
      </span>
    </div>

    <!-- Issue date -->
    <div
      class="tw-flex-1 invoice-item-column issue-date-column tw-truncate"
      data-test="issue-date"
    >
      <span
        class="value tw-text-left tw-truncate tw-ml-2"
        v-text="issueDate"
      />
    </div>

    <!-- Invoice reference -->
    <div
      class="tw-flex-1 invoice-item-column invoice-reference-column tw-truncate"
      data-test="invoice-reference"
    >
      <span
        class="tw-flex-1 value tw-truncate tw-ml-2"
        v-text="data.reference"
      />
    </div>

    <!-- Mission reference -->
    <div
      v-if="!isUserShipper"
      class="tw-flex-1 invoice-item-column mission-reference-column tw-text-gray-700 tw-truncate"
      data-test="mission-reference"
    >
      <span
        v-if="data.mission"
        v-text="data.mission.reference"
        class="tw-flex-1 value tw-text-left tw-truncate tw-ml-2 mission-reference"
      />
    </div>

    <!-- Total HT -->
    <div
      v-if="isUserShipper"
      class="tw-flex-1 invoice-item-column invoice-item-price-without-tax tw-text-right"
    >
      <span
        v-text="total_tax_excluded"
        class="value tw-mr-2"
        data-test="price-tax-excluded"
      />
    </div>

    <!-- Total -->
    <div class="tw-flex-1 invoice-item-column invoice-item-price tw-text-right tw-font-medium">
      <span
        v-text="total_tax_included"
        class="value tw-mr-2"
        data-test="price-tax-included"
      />
    </div>

    <!-- Download invoice -->
    <div
      v-if="isUserShipper"
      class="invoice-item-inline__download-column tw-flex-1 tw-flex tw-justify-end"
    >
      <ctk-btn-file
        :id="`download-button-inline-${data.reference}`"
        :url="fileUrl"
        :popover-title="$t('app.labels.invoice')"
        :file-name="`invoice-${data.reference}.pdf`"
        class="tw-mr-4"
        data-test="invoice-button"
      >
        <button
          :title="$options.filters.capitalize($t('app.buttons.download'))"
          type="button"
          class="invoice-item-inline__download btn tw-rounded-full tw-w-10 tw-h-10 tw-p-0"
          data-test="pod-btn"
        >
          <ui-ctk-icon
            name="save1"
            data-test="icon"
          />
        </button>
      </ctk-btn-file>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import InvoiceItemAlert from './../InvoiceItemAlert/index.vue'
  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'

  /**
   * @module component - InvoiceItemInline
   * @param {object} data
   * @param {boolean} [checked=false]
   * @param {boolean} [selectable=false]
   * @param {boolean} [hasError=false]
   * @param {boolean} [hasErrorTooltip=true]
   */
  export default defineComponent({
    name: 'InvoiceItemInline',
    components: {
      InvoiceItemAlert,
      CtkBtnFile
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      checked: {
        type: Boolean,
        default: false
      },
      selectable: {
        type: Boolean,
        default: false
      },
      hasError: {
        type: Boolean,
        default: false
      },
      hasErrorTooltip: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      /**
       * @function fileUrl
       * @returns {string}
       */
      fileUrl () {
        return `v2/companies/${this.getCid}/invoices/${this.data.uuid}/download`
      },
      /**
       * Returns the formatted issue date
       * @function issueDate
       * @return {string} date
       */
      issueDate () {
        return this.$moment(this.data.issue_date).format('LL')
      },
      /**
       * Returns the formatted due date
       * @function dueDate
       * @return {string} date
       */
      dueDate () {
        return this.$moment(this.havePayment
          ? this.data.payment.issue_date
          : this.data.due_date
        ).format('LL')
      },
      /**
       * Returns the formatted total
       * @function total_tax_excluded
       * @return {string} total_tax_excluded
       */
      total_tax_excluded () {
        return this.$options.filters.currency(this.data.amount, this.data.currency, this._i18n.locale)
      },
      /**
       * Returns the formatted total
       * @function total_tax_included
       * @return {string} total_tax_included
       */
      total_tax_included () {
        return this.$options.filters.currency(this.data.vat_included_amount, this.data.currency, this._i18n.locale)
      },
      /**
       * @function havePayment
       * @returns {boolean}
       */
      havePayment () {
        return typeof this.data.payment !== 'undefined'
      },
      /**
       * Returns if the item is considered as paid or not
       * @function isPaid
       * @return {boolean} paid
       */
      isPaid () {
        return this.data.state === 'paid'
      }
    }
  })
</script>

<style lang="scss" scoped>
.invoice-item {
  height: 35px;
  color: $primary-text;
  transition: box-shadow 200ms;
  transform: scale(1, 1);
}
.invoice-item:hover {
  background-color: darken(white, 3%);
}
.invoice-item:focus {
  box-shadow: 0 0 0 0.2em rgba($info, 0.5);
}
.invoice-item.active, .invoice-item.router-link-exact-active {
  box-shadow: 0 1px 3px rgba(black, 0.2);
  background-color: $divider;
}
.invoice-item .mission-reference {
  color: rgba(0, 0, 0, 0.54) !important;
}
.invoice-item-column {
  vertical-align: middle;
  margin: auto 0;
  text-align: left;
  width: 16.666%;
}
.invoice-item-inline__download-column {
  flex: 0.8;
}
.invoice-item-price, .invoice-item-price-without-tax {
  text-align: right;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .invoice-item .invoice-reference-column .invoice-reference-column-icon {
    display: none;
  }
}
.invoice-item .invoice-alert-column {
  width: 32px;
}
.invoice-item .invoice-reference-column span {
  user-select: all;
}
.invoice-item .invoice-reference-column-icon {
  width: 18px;
}
.invoice-item .invoice-item-price, .invoice-item .invoice-item-price-without-tax, .invoice-item .due-date-column, .invoice-item .issue-date-column {
  flex: 0.8;
}
.invoice-item.error {
  background-color: #E9D2D0;
  border-color: darken(#E9D2D0, 5%);
}
.invoice-item:hover.error, .invoice-item.error.active, .invoice-item.error.router-link-exact-active {
  background-color: darken(#E9D2D0, 5%);
  border-color: darken(#E9D2D0, 5%);
}
.invoice-item .invoice-checkbox {
  min-height: auto;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .invoice-item {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    overflow: hidden;
    border: 1px solid $divider;
    height: auto;
  }
  .invoice-item .invoice-item-column {
    display: block;
    width: 50%;
    flex: initial;
    padding: 4px 16px;
    border: none !important;
  }
  .invoice-item .invoice-reference-column span {
    display: inline-block;
  }
  .invoice-item .issue-date-column, .invoice-item .invoice-alert-column, .invoice-item .mission-reference-column {
    display: none;
  }
}
@media only screen and (max-width: $breakpoint-mobile-s) {
  .invoice-item-column {
    width: 100% !important;
  }
  .invoice-item-column, .invoice-item-column span {
    text-align: center !important;
  }
  .invoice-item .due-date-column span {
    justify-content: center;
  }
}
.invoice-item-inline__download {
  background: transparent;
}
.invoice-item-inline__download:hover {
  background: $light-gray;
}
.invoice-item-inline__download:focus:not(:focus-visible) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
</style>
