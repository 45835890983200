<template>
  <div
    :class="{
      'billing-invoice-amounts-header--has-outstanding': isBillingGrouped
    }"
    class="billing-invoice-amounts-header tw-flex tw-rounded"
  >
    <div
      class="billing-invoice-amounts-header__wrapper tw-flex tw-items-center tw-rounded"
    >
      <billing-invoice-amounts-header-section
        :amount="$t('price_incl_tax', {
          price: $options.filters.currency(getInvoicesMetrics.amounts.payment_pending, 'EUR', this.$i18n.locale)
        })"
        class="tw-mr-8"
        data-test="pending"
      >
        <i18n
          path="billing.labels.amount_to_pay"
          tag="span"
          data-test="pending-content"
        >
          <template #invoices>
            <span
              v-text="$tc('billing.labels.invoices', getInvoicesMetrics.counts.payment_pending, {
                count: getInvoicesMetrics.counts.payment_pending
              })"
              class="tw-font-medium"
            />
          </template>
        </i18n>
      </billing-invoice-amounts-header-section>

      <billing-invoice-amounts-header-section
        :amount="$t('price_incl_tax', {
          price: $options.filters.currency(getInvoicesMetrics.amounts.payment_late, 'EUR', this.$i18n.locale)
        })"
        :tooltip="$t('billing.paragraphs.payment_delay')"
        primary
        data-test="late"
      >
        <span
          v-if="getInvoicesMetrics && getInvoicesMetrics.counts"
          v-markdown="$tc('billing.labels.amount_late', getInvoicesMetrics.counts.payment_late, {
            count: getInvoicesMetrics.counts.payment_late
          })"
          data-test="late-label"
        />
      </billing-invoice-amounts-header-section>

      <billing-invoice-amounts-header-section
        v-if="isBillingGrouped"
        :amount="$t('price_incl_tax', {
          price: $options.filters.currency(getInvoicesMetrics.amounts.payment_proforma, 'EUR', this.$i18n.locale)
        })"
        :tooltip="$t('billing.paragraphs.outstanding_amount')"
        class="tw-ml-8"
        data-test="outstanding-amount"
      >
        <span
          v-text="$t('billing.labels.amount_outstanding')"
          class="tw-font-medium"
          data-test="outstanding-label"
        />
      </billing-invoice-amounts-header-section>
    </div>
    <billing-invoice-amounts-header-explanation
      :tooltip="$t('billing.paragraphs.payment_conditions')"
      data-test="explanation"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import useTooltipDialog from '@/composables/useTooltipDialog'
  import { i18n } from '@/locales'
  import BillingInvoiceAmountsHeaderSection from './_subs/BillingInvoiceAmountsHeaderSection/index.vue'
  import BillingInvoiceAmountsHeaderExplanation from './_subs/BillingInvoiceAmountsHeaderExplanation/index.vue'

  /**
   * @module component - BillingInvoiceAmountsHeader
   */
  export default defineComponent({
    name: 'BillingInvoiceAmountsHeader',
    components: {
      BillingInvoiceAmountsHeaderSection,
      BillingInvoiceAmountsHeaderExplanation
    },
    setup () {
      const { click, haveDialog } = useTooltipDialog(null, i18n.t('billing.paragraphs.payment_conditions'))

      return {
        click,
        haveDialog
      }
    },
    computed: {
      ...mapGetters('auth', [
        'isCompanyPayingCC',
        'isBillingGrouped'
      ]),
      ...mapGetters('billing', [
        'getInvoicesMetrics'
      ]),
      /**
       * @function tooltipOptions
       * @returns {{ title: string, disabled: boolean }}
       */
      tooltipOptions () {
        return {
          // @ts-ignore
          title: this.$t('billing.paragraphs.payment_conditions'),
          disabled: this.haveDialog
        }
      },
      /**
       * @function checks
       * @returns {Array<string>}
       */
      checks () {
        if (this.isCompanyPayingCC) {
          return [
            // @ts-ignore
            this.$t('app.values.payment_conditions.cash'),
            // @ts-ignore
            this.$t('app.values.payment_conditions.cc')
          ]
        }

        return [
          // @ts-ignore
          this.$t('app.values.payment_conditions.thirty_days_invoice'),
          // @ts-ignore
          this.$t('app.values.payment_conditions.transfer_cc')
        ]
      }
    }
  })
</script>

<style lang="scss" scoped>
.billing-invoice-amounts-header__wrapper {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 1.5rem;
  border: 1px solid $divider;
  flex: 0.5;
  margin-right: 26px;
}
.billing-invoice-amounts-header--has-outstanding .billing-invoice-amounts-header__wrapper {
  flex: 0.75;
}
.billing-invoice-amounts-header .billing-invoice-amounts-header-section:not(:last-child) {
  position: relative;
}
.billing-invoice-amounts-header .billing-invoice-amounts-header-section:not(:last-child)::after {
  position: absolute;
  content: '';
  right: 0;
  width: 1px;
  height: 100%;
  background-color: $divider;
}
.billing-invoice-amounts-header .billing-invoice-amounts-header-explanation {
  flex: 0.25;
  min-height: 50px;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .billing-invoice-amounts-header {
    flex-direction: column;
  }
  .billing-invoice-amounts-header .billing-invoice-amounts-header-explanation {
    padding: 16px;
  }
  .billing-invoice-amounts-header__wrapper {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .billing-invoice-amounts-header__wrapper {
    display: block;
    width: 100%;
    padding: 20px 16px;
  }
  .billing-invoice-amounts-header .billing-invoice-amounts-header-section {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .billing-invoice-amounts-header .billing-invoice-amounts-header-section:not(:last-child) {
    margin-bottom: 32px;
  }
  .billing-invoice-amounts-header .billing-invoice-amounts-header-section:not(:last-child)::after {
    left: 0;
    bottom: -16px;
    width: 100%;
    height: 1px;
  }
}
</style>
