<template>
  <div
    :class="{
      'tw-pb-16 md:tw-pb-0': isUserShipper
    }"
    class="invoices tw-flex tw-flex-1"
  >
    <div class="tw-flex tw-flex-col tw-flex-1">
      <invoices-header
        v-if="getInvoicesMetrics && getInvoicesMetrics.counts"
        :title="title"
        :subtitle="subtitle"
        class="flex-fixed mb-2"
      />

      <div
        v-infinite-scroll="retrieveMoreMobile"
        class="tw-w-screen 2sm:tw-w-auto content tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto"
      >
        <div class="invoices__subheader">
          <billing-header
            v-if="!isUserShipper && hasBillingHeader && getInvoicesMeta.amounts && getInvoicesMeta.amounts.pending"
            :amount="getInvoicesMeta.amounts.pending"
            class="flex-fixed"
          />
          <billing-invoice-amounts-header
            v-if="isUserShipper && getInvoicesMetrics && getInvoicesMetrics.counts && state === 'pending'"
            class="mb-1"
          />
        </div>

        <billing-pay-header
          v-if="hasPaySection"
          class="invoices__subheader tw-mt-6 md:tw-mt-12 tw-top-0"
          data-test="pay-header"
        />

        <transition
          v-if="!$wait.is('loading invoices')"
          name="fade"
        >
          <bill-table
            v-if="count > 0"
            ref="table"
            :items="items"
            :loading-layer="false"
            :loading="$wait.is('loading more invoices')"
            :loading-props="{
              loadMore: $t('billing.load_more_invoices'),
              loaded: $t('billing.all_invoices_loaded')
            }"
            :selectable="hasPaySection"
            :can-load-more="count && canRetrieveMore && !$wait.is('loading more invoices')"
            :class="{
              'has-pay-header': hasPaySection
            }"
            class="tw-flex-1 tw-mt-1"
            @infinite-scroll="retrieveMore"
          />
          <ctk-empty-state
            v-if="count === 0"
            :title="$t('billing.no_invoices')"
          />
        </transition>
      </div>

      <ctk-loading-layer
        v-if="$wait.is('loading invoices')"
        :background="true"
      >
        {{ $t('billing.loading_invoices') }}
      </ctk-loading-layer>
    </div>

    <billing-floating-footer
      v-if="hasPaySection"
      class="md:tw-hidden"
    />

    <!-- Dialogs -->
    <invoices-pay-dialog
      v-model="dialogs.pay"
      data-test="pay-dialog"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  import Hotjar from '@/plugins/VueHotjar'

  import CtkEmptyState from '@/components/CtkEmptyState/index.vue'
  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'

  import BillingHeader from '@/views/Common/Billing/components/BillingHeader/index.vue'
  import BillingInvoiceAmountsHeader from '@/views/Shippers/Billing/components/BillingInvoiceAmountsHeader/index.vue'
  import BillingFloatingFooter from '@/views/Common/Billing/_subs/Invoices/components/BillingFloatingFooter/index.vue'
  import BillTable from './_subs/BillTable/index.vue'
  import InvoicesHeader from './_subs/InvoicesHeader/index.vue'
  import BillingPayHeader from './components/BillingPayHeader/index.vue'
  import InvoicesPayDialog from '@/views/Shippers/Billing/components/InvoicesPayDialog/index.vue'

  import store from '@/store'
  import { EventBus } from '@/services/EventBus'
  import useSticky from '@/composables/useSticky'

  const states = ['pending', 'paid']

  /**
   * @module view - invoices
   */
  export default defineComponent({
    name: 'Invoices',
    components: {
      CtkEmptyState,
      BillingHeader,
      BillTable,
      InvoicesHeader,
      CtkLoadingLayer,
      BillingPayHeader,
      BillingFloatingFooter,
      InvoicesPayDialog,
      BillingInvoiceAmountsHeader
    },
    setup () {
      const { create: updatePayHeader } = useSticky('.billing-pay-header')
      const { create: updateTableHeader } = useSticky('.billing-table-header')

      function updateSticky (options = {}) {
        updatePayHeader()
        updateTableHeader(options)
      }

      return {
        updateSticky
      }
    },
    data () {
      return {
        pagination: 1,
        sidebar: true,
        latestInvoice: null,
        dialogs: {
          pay: false
        }
      }
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      store.commit('wait/START', 'loading invoices')

      /**
       * Redirect to the pending invoices if
       * there is no state specified in the URL.
       */
      if (to.name === 'Invoices' && !to.params.stateUuid) {
        next({
          name: 'Invoices',
          params: {
            stateUuid: 'pending'
          },
          query: to.query
        })
      }

      store.dispatch('billing/retrieveInvoicesMetrics')
      store.dispatch('billing/retrievePaymentsMetrics')
      next()
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('billing', [
        'getInvoicesItems',
        'getInvoicesMeta',
        'getInvoicesCurrentPage',
        'getCurrentInvoice',
        'getInvoicesMetrics'
      ]),
      ...mapGetters('billing/credit-card-payment', [
        'getInvoices'
      ]),
      /**
       * Returns true only if the payment sections should be shown
       * @function hasPaySection
       * @returns {boolean}
       */
      hasPaySection () {
        if (!this.isUserShipper) return false
        if (this.state !== 'pending') return false
        if (this.count === 0) return false
        if (this.$wait.is('loading invoices')) return false

        return true
      },
      /**
       * Returns a formatted list of items including a "checked" property
       * @function items
       * @returns {Array<any>}
       */
      items () {
        return this.getInvoicesItems.map(item => {
          const checked = this.getInvoices.findIndex(v => v.uuid === item.uuid) !== -1

          return {
            ...item,
            checked
          }
        })
      },
      /**
       * @function count
       * @returns {number|null}
       */
      count () {
        const metrics = this.getInvoicesMetrics
        if (!metrics) {
          return null
        }

        const { counts } = metrics
        const state = this.isUserShipper && this.state === 'pending'
          ? 'payment_pending'
          : this.state

        return state && counts && counts[state]
      },
      /**
       * @function title
       * @returns {string|null}
       */
      title () {
        if (!this.state || this.count === null) return null
        const isPending = this.state === 'pending'

        return this.$tc(isPending && this.isUserShipper
          ? 'billing.title.invoices.pending_shipper'
          : `billing.title.invoices.${this.state}`, this.count)
      },
      /**
       * @function subtitle
       * @returns {string|null}
       */
      subtitle () {
        return this.count !== null
          ? this.$tc('billing.labels.invoices', this.count, {
            count: this.$n(this.count)
          })
          : null
      },
      /**
       * Returns the current state either from the URL or from the
       * currently selected invoice.
       * @function state
       * @returns {string|null} state
       */
      state () {
        const { stateUuid } = this.$route.params
        const state = (this.getCurrentInvoice && this.getCurrentInvoice.state) || (states.includes(stateUuid) ? stateUuid : null)
        return state === 'waiting_pod' ? 'pending' : state
      },
      /**
       * Returns true if we're supposed to show the billing header.
       * @function hasBillingHeader
       * @returns {boolean}
       */
      hasBillingHeader () {
        const { amounts } = this.getInvoicesMeta
        const currentInvoice = this.getCurrentInvoice

        const isCurrentInvoicePending = currentInvoice && (currentInvoice.state === 'pending' || currentInvoice.state === 'waiting_pod')
        const hasPendingInvoices = amounts && amounts.pending > 0

        return (this.state === 'pending' || isCurrentInvoicePending) && hasPendingInvoices && !this.$wait.is('loading invoices')
      },
      /**
       * Returns true if we can retrive more invoices
       * @function canRetrieveMore
       * @returns {boolean} retrieveMore
       */
      canRetrieveMore () {
        const { pagination } = this.getInvoicesMeta
        return pagination && pagination.current_page < pagination.page_count
      }
    },
    beforeRouteUpdate (to, from, next) {
      if (to.name === 'Invoices' && !to.params.stateUuid) {
        next({
          name: 'Invoices',
          params: {
            stateUuid: 'pending'
          }
        })
        return false
      }

      /**
       * Temporarily save the last invoice viewed for routing purposes.
       */
      this.latestInvoice = this.getCurrentInvoice
      next()
    },
    watch: {
      hasPaySection: function (v) {
        this.updateSticky({
          stickyBitStickyOffset: v ? 56 : 0
        })
      },
      $route: function (to, from) {
        if (to.name === 'Invoices' && states.includes(to.params.stateUuid) && from.params.stateUuid && !states.includes(from.params.stateUuid)) {
          // Do nothing to close the side bar and not reload the invoices list.
          if (states.includes(to.params.stateUuid) && states.includes(from.params.stateUuid) && to.params.stateUuid !== from.params.stateUuid) {
            // If we're switching between states, reload the invoices
            this.updateInvoices(to.params.stateUuid)
          }

          // If we're from a uuid to state case, where the state is different from the current
          // invoice state.
          if (
            states.includes(to.params.stateUuid) &&
            !states.includes(from.params.stateUuid) &&
            this.latestInvoice &&
            this.latestInvoice.state !== to.params.stateUuid
          ) {
            this.updateInvoices(to.params.stateUuid)
          }
          return
        }

        if (!states.includes(to.params.stateUuid)) {
          /**
           * When the user reaches the invoice view, close the
           * main loader and fetch the invoice informations.
           */
          this.fetchInvoice()
        } else {
          if (this.state) this.updateInvoices(this.state)
          this.retrieveInvoicesMetrics()
          this.retrievePaymentsMetrics()
        }
      }
    },
    mounted () {
      if (this.$route.params.stateUuid && !states.includes(this.$route.params.stateUuid)) {
        /**
         * If we're in the detail view, fetch the current invoice
         */
        this.fetchInvoice(true)
      } else {
        if (this.state) this.updateInvoices(this.state)
      }

      EventBus.$on('invoices:dialogs:pay', () => {
        this.dialogs.pay = true
        Hotjar.tag('Pay Invoices')
      })

      EventBus.$on('invoices:refresh', () => {
        if (this.state) this.updateInvoices(this.state)

        this.retrieveInvoicesMetrics()
        this.retrievePaymentsMetrics()
      })

      this.unselectAll()
    },
    methods: {
      ...mapActions('billing', [
        'retrieveInvoice',
        'retrieveInvoices',
        'retrieveAllInvoices',
        'retrieveMoreInvoices',
        'retrieveInvoiceShipment',
        'retrieveInvoicesMetrics',
        'retrievePaymentsMetrics',
        'retrieveInvoiceShipmentBilling',
        'retrieveInvoiceMission',
        'retrieveInvoiceMissionBilling'
      ]),
      ...mapActions('billing/credit-card-payment', [
        'setInvoices',
        'unselectAll'
      ]),
      /**
       * Called whenever the scroll arrives at the end.
       * The purpose of this bifurcation is to handle the mobile fetching
       * separatly since we use the full-page scroll, not on the list.
       * @function retrieveMoreMobile
       */
      retrieveMoreMobile () {
        const width = document.documentElement.clientWidth
        if (width <= 455) {
          this.retrieveMore()
        }
      },
      async fetchInvoice (initialLoading = false) {
        this.$wait.start('loading invoice')
        this.$err.hide('no invoice')

        this.retrieveInvoice({
          uuid: this.$route.params.stateUuid
        })
          .then(async (res) => {
            /**
             * Fetch the missions according to the current mission state
             */
            if (initialLoading) {
              await this.updateInvoices(res.data.state, {
                keep: this.$route.params.stateUuid
              })
            }

            /**
             * Either fetch the mission if the user is a carrier
             * or fetch the shipment if the user is a shipper
             */
            if (this.isUserShipper) await this.fetchShipment(res.data, res.data.shipment)
            else await this.fetchMission(res.data, res.data.mission)
          })
          .catch(async (err) => {
            if (!err.response) {
              this.$wait.end('loading invoice')
              return
            }

            this.$router.replace({
              name: 'Invoices',
              params: {
                stateUuid: 'pending'
              }
            }).catch(() => {})

            this.$wait.end('loading invoice')
            this.$err.show('no invoice')
            if (initialLoading) {
              await this.updateInvoices('pending')
            }
          })
      },
      /**
       * Fetch the mission data associated with this invoice, if the mission
       * exists.
       * @function fetchMission
       * @param {object} mission
       */
      async fetchMission (invoice, mission) {
        if (mission) {
          await this.retrieveInvoiceMission({
            invoiceUuid: invoice.uuid,
            uuid: mission.uuid
          })

          await this.retrieveInvoiceMissionBilling({
            invoiceUuid: invoice.uuid,
            uuid: mission.uuid
          })
          this.$wait.end('loading invoice')
        } else {
          this.$wait.end('loading invoice')
        }
      },
      /**
       * Fetch the shipment data associated with thi sinvoice, if the shipment
       * exists.
       * @function fetchShipment
       * @param {object} shipment
       */
      async fetchShipment (invoice, shipment) {
        if (shipment) {
          const { data } = await this.retrieveInvoiceShipment({
            sid: shipment.uuid,
            invoiceUuid: invoice.uuid
          })
          if (data) {
            await this.retrieveInvoiceShipmentBilling({
              sid: shipment.uuid,
              invoiceUuid: invoice.uuid
            })
          }

          this.$wait.end('loading invoice')
        } else {
          this.$wait.end('loading invoice')
        }
      },
      /**
       * @function retriveMore
       */
      retrieveMore () {
        if (this.canRetrieveMore) {
          if (!this.state) return

          this.$wait.start('loading more invoices')
          this.retrieveMoreInvoices(this.state)
            .catch(() => {})
            .finally(() => {
              this.$wait.end('loading more invoices')
            })
        }
      },
      /**
       * @function selectLateInvoices
       */
      selectLateInvoices () {
        const now = moment().startOf('day')
        const invoices = this.getInvoicesItems
          .filter(invoice => now >= moment(invoice.due_date).startOf('day') && invoice.state !== 'paid')
          .map(invoice => ({
            uuid: invoice.uuid,
            total: invoice.vat_included_amount
          }))

        this.setInvoices(invoices)
      },
      /**
       * @function updateInvoices
       * @param {string} state
       * @param {object} [options={}]
       * @param {string} [loader='loading invoices']
       * @returns {Promise<any>}
       */
      updateInvoices (state, options = {}, loader = 'loading invoices') {
        const requestMethod = this.isUserShipper && state === 'pending'
          ? this.retrieveAllInvoices
          : this.retrieveInvoices

        this.$wait.start(loader)
        return requestMethod({
          page: 1,
          state: this.state || state,
          options
        })
          .then(() => {
            if (this.$route.query.selectAll) {
              this.selectLateInvoices()

              this.$router.replace({
                ...this.$route,
                query: {}
              }).catch(() => {})
            }
          })
          .catch(() => {})
          .finally(() => {
            this.updateSticky({
              stickyBitStickyOffset: this.hasPaySection ? 56 : 0
            })
            this.$wait.end(loader)
          })
      }
    },
    beforeDestroy () {
      const events = ['invoices:refresh', 'invoices:dialogs:pay']
      events.forEach(event => EventBus.$off(event))
    }
  })
</script>

<style lang="scss">
@media only screen and (max-width: $breakpoint-mobile-l) {
  .invoices .content {
    overflow-y: auto;
  }
  .invoices .billing-table-content, .invoices .bill-table {
    overflow-y: initial;
  }
}
.invoices .billing-pay-header {
  z-index: 20;
}
.invoices .billing-table-header {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  top: 0px;
  z-index: 10;
}
.invoices-header, .invoices__subheader, .invoices .bill-table {
  padding: 0 30px 0 53px;
}
.invoices-header::after {
  left: 53px;
  width: calc(100% - 83px);
}
.invoices, .invoices .content {
  position: relative;
}
@media only screen and (max-width: $breakpoint-laptop-s) {
  .invoices .billing-help {
    display: none;
  }
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .invoices__subheader, .invoices .bill-table {
    padding: 0 16px;
  }
  .invoices-header {
    padding: 0 16px 0 39px;
  }
  .invoices-header::after {
    left: 39px;
    width: calc(100% - 55px);
  }
}
</style>
