<template>
  <div
    class="tw-flex tw-flex-col invoice-item-card tw-w-full tw-rounded"
  >
    <!-- Alert icon -->
    <div class="invoice-item-card__alert">
      <!-- Select checkbox -->
      <b-form-checkbox
        v-if="selectable"
        :checked="checked"
        class="invoice-checkbox tw-ml-2"
        data-test="checkbox"
        @change="$emit('update:checked', !checked)"
      />

      <invoice-item-alert
        v-if="hasError"
        :invoice="data"
        :has-tooltip="false"
        data-test="alert"
      />
    </div>

    <!-- References -->
    <div class="invoice-item-card__references tw-mb-2">
      <!-- Invoice reference -->
      <div
        class="invoice-reference-column tw-truncate"
        data-test="invoice-ref"
      >
        <span class="tw-flex-1 value tw-truncate">
          {{ data.reference }}
        </span>
      </div>

      <!-- Mission reference -->
      <div
        v-if="!isUserShipper"
        class="mission-reference-column tw-truncate"
      >
        <span
          v-if="data.mission"
          class="tw-flex-1 value text-left tw-truncate tw-mr-1 mission-reference"
          data-test="mission-ref"
        >
          {{ data.mission.reference }}
        </span>
      </div>
    </div>

    <!-- Dates -->
    <div class="tw-mb-2">
      <!-- Issue date -->
      <div class="issue-date-column tw-truncate">
        <span
          class="value text-left tw-truncate"
          data-test="issue-date"
        >
          {{ $t('billing.values.issue_date', {
            date: issueDate
          }) }}
        </span>
      </div>

      <!-- Due date -->
      <div class="due-date-column tw-truncate">
        <template
          v-if="isUserShipper && hasError"
        >
          <span
            class="value text-left tw-flex tw-truncate"
            data-test="payment-due-date"
          >
            <div
              class="invoice-item-card-date tw-truncate"
            >
              {{ $t('billing.values.payment_date', {
                date: dueDate
              }) }}
            </div>
          </span>
        </template>
        <template
          v-else
        >
          <span
            class="value text-left tw-flex tw-truncate"
            data-test="payment-due-date"
          >
            <div
              class="invoice-item-card-date tw-truncate"
            >
              <template
                v-if="havePayment"
              >
                {{ $t('billing.values.payment_date_paid', {
                  date: dueDate
                }) }}
              </template>
              <template
                v-else
              >
                {{ $t('billing.values.payment_date', {
                  date: dueDate
                }) }}
              </template>
            </div>
          </span>
        </template>
      </div>
    </div>

    <div class="tw-flex tw-justify-between invoice-item-card__price">
      <!-- Total HT -->
      <div
        v-if="isUserShipper"
        class="tw-flex-1 invoice-item-card-price-without-tax"
      >
        <span
          class="value tw-pr-3"
          data-test="price-tax-excluded"
        >
          {{ $t('price_excl_tax', {
            price: total_tax_excluded
          }) }}
        </span>
      </div>

      <!-- Total -->
      <div class="tw-flex-1 invoice-item-card-price">
        <span
          class="value tw-pr-3"
          data-test="price-tax-included"
        >
          {{ $t('price_incl_tax', {
            price: total_tax_included
          }) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import InvoiceItemAlert from './../InvoiceItemAlert/index.vue'

  /**
   * @module component - InvoiceItemCard
   * @param {object} data
   * @param {boolean} [checked=false]
   * @param {boolean} [selectable=false]
   * @param {boolean} [hasError=false]
   */
  export default defineComponent({
    name: 'InvoiceItemCard',
    components: {
      InvoiceItemAlert
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      hasError: {
        type: Boolean,
        default: false
      },
      checked: {
        type: Boolean,
        default: false
      },
      selectable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      /**
       * Returns the formatted issue date
       * @function issueDate
       * @return {string} date
       */
      issueDate () {
        return this.$moment(this.data.issue_date).format('LL')
      },
      /**
       * Returns the formatted due date
       * @function dueDate
       * @return {string} date
       */
      dueDate () {
        return this.$moment(this.havePayment
          ? this.data.payment.issue_date
          : this.data.due_date
        ).format('LL')
      },
      /**
       * Returns the formatted total
       * @function total_tax_excluded
       * @return {string} total_tax_excluded
       */
      total_tax_excluded () {
        return this.$options.filters.currency(this.data.amount, this.data.currency, this._i18n.locale)
      },
      /**
       * Returns the formatted total
       * @function total_tax_included
       * @return {string} total_tax_included
       */
      total_tax_included () {
        return this.$options.filters.currency(this.data.vat_included_amount, this.data.currency, this._i18n.locale)
      },
      /**
       * @function havePayment
       * @returns {boolean}
       */
      havePayment () {
        return typeof this.data.payment !== 'undefined'
      },
      /**
       * Returns if the item is considered as paid or not
       * @function isPaid
       * @return {boolean} paid
       */
      isPaid () {
        return this.data.state === 'paid'
      }
    }
  })
</script>

<style lang="scss" scoped>

  .invoice-item-card {
    position: relative;

    &-price,
    &-price-without-tax {
      color: $info;
      font-weight: 500;
      font-size: 16px;
    }

    &__alert {
      position: absolute;
      width: 2rem;
      left: -2rem;
    }

    .invoice-reference-column {
      span {
        user-select: all;
      }

      &-icon {
        width: 18px;
      }
    }

    .invoice-item-price,
    .due-date-column,
    .issue-date-column {
      flex: 0.8;
    }
  }

</style>
