<template>
  <aside class="payment-detail tw-flex tw-flex-col tw-flex-1">
    <slot
      name="header"
    />

    <slot
      v-if="getCurrentPaymentInvoices && getCurrentPaymentInvoices.length > 0 && getCurrentPayment || $wait.is('fetching payment invoices')"
    />
    <slot
      v-else
      name="empty-state"
    >
      <ctk-empty-state
        :title="$t('billing.title.no_payment')"
        class="tw-flex-1"
        sidebar
        data-test="empty-state"
      />
    </slot>
  </aside>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkEmptyState from '@/components/CtkEmptyState/index.vue'

  /**
   * @module component - PaymentDetail
   */
  export default {
    name: 'PaymentDetail',
    components: {
      CtkEmptyState
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentPayment',
        'getCurrentPaymentInvoices'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .payment-detail {
    background-color: lighten($light-gray, 2%);
  }

</style>
