var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing-invoice-amounts-header tw-flex tw-rounded",class:{
    'billing-invoice-amounts-header--has-outstanding': _vm.isBillingGrouped
  }},[_c('div',{staticClass:"billing-invoice-amounts-header__wrapper tw-flex tw-items-center tw-rounded"},[_c('billing-invoice-amounts-header-section',{staticClass:"tw-mr-8",attrs:{"amount":_vm.$t('price_incl_tax', {
        price: _vm.$options.filters.currency(_vm.getInvoicesMetrics.amounts.payment_pending, 'EUR', this.$i18n.locale)
      })}},[_c('i18n',{attrs:{"path":"billing.labels.amount_to_pay","tag":"span"},scopedSlots:_vm._u([{key:"invoices",fn:function(){return [_c('span',{staticClass:"tw-font-medium",domProps:{"textContent":_vm._s(_vm.$tc('billing.labels.invoices', _vm.getInvoicesMetrics.counts.payment_pending, {
              count: _vm.getInvoicesMetrics.counts.payment_pending
            }))}})]},proxy:true}])})],1),_c('billing-invoice-amounts-header-section',{attrs:{"amount":_vm.$t('price_incl_tax', {
        price: _vm.$options.filters.currency(_vm.getInvoicesMetrics.amounts.payment_late, 'EUR', this.$i18n.locale)
      }),"tooltip":_vm.$t('billing.paragraphs.payment_delay'),"primary":""}},[(_vm.getInvoicesMetrics && _vm.getInvoicesMetrics.counts)?_c('span',{directives:[{name:"markdown",rawName:"v-markdown",value:(_vm.$tc('billing.labels.amount_late', _vm.getInvoicesMetrics.counts.payment_late, {
          count: _vm.getInvoicesMetrics.counts.payment_late
        })),expression:"$tc('billing.labels.amount_late', getInvoicesMetrics.counts.payment_late, {\n          count: getInvoicesMetrics.counts.payment_late\n        })"}]}):_vm._e()]),(_vm.isBillingGrouped)?_c('billing-invoice-amounts-header-section',{staticClass:"tw-ml-8",attrs:{"amount":_vm.$t('price_incl_tax', {
        price: _vm.$options.filters.currency(_vm.getInvoicesMetrics.amounts.payment_proforma, 'EUR', this.$i18n.locale)
      }),"tooltip":_vm.$t('billing.paragraphs.outstanding_amount')}},[_c('span',{staticClass:"tw-font-medium",domProps:{"textContent":_vm._s(_vm.$t('billing.labels.amount_outstanding'))}})]):_vm._e()],1),_c('billing-invoice-amounts-header-explanation',{attrs:{"tooltip":_vm.$t('billing.paragraphs.payment_conditions')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }