<template>
  <div class="payment-detail-invoices p-3">
    <div>
      <pricing-invoices-title
        v-if="getCurrentPaymentInvoices"
        :count="getCurrentPaymentInvoices.length"
      />
      <pricing-invoice
        v-for="invoice in getCurrentPaymentInvoices"
        :key="invoice.uuid"
        :invoice="invoice"
        :currency="invoice.currency"
        :class="`${invoice.reference}`"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PricingInvoice from '@/components/CtkPricing/_subs/PricingInvoice/index.vue'
  import PricingInvoicesTitle from '@/components/CtkPricing/_subs/PricingInvoicesTitle/index.vue'

  /**
   * @module components - PaymentDetailInvoices
   */
  export default {
    name: 'PaymentDetailInvoices',
    components: {
      PricingInvoice,
      PricingInvoicesTitle
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentPaymentInvoices'
      ])
    }
  }
</script>
