var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-item',{staticClass:"credit-card-payment-item tw-flex tw-justify-between tw-items-center tw-mb-2 tw-rounded",attrs:{"to":{
    name: 'CreditCardPayments',
    params: {
      uuid: _vm.data.uuid
    }
  },"touchable-type":"router-link"}},[_c('div',{staticClass:"tw-flex-col 2sm:tw-flex-row tw-flex 2sm:tw-items-center tw-w-full tw-my-auto"},[_c('div',{staticClass:"credit-card-payment-item__column tw-my-auto tw-mx-0 credit-card-payment-item__issue-date tw-flex-1 tw-pl-4 tw-mt-2 md:tw-mt-auto tw-flex tw-items-center"},[_c('UiBadge',{staticClass:"tw-my-auto",attrs:{"color":"teal"}},[_c('span',{staticClass:"credit-card-payment-item__issue-date__inline",domProps:{"textContent":_vm._s(_vm.issue)}}),_c('span',{staticClass:"credit-card-payment-item__issue-date__card",domProps:{"textContent":_vm._s(_vm.$t('billing.values.issue_date', {
            date: _vm.issue
          }))}})])],1),_c('div',{staticClass:"credit-card-payment-item__column tw-my-auto tw-mx-0 credit-card-payment-item__reference tw-select-all tw-flex-1 tw-pl-2",domProps:{"textContent":_vm._s(_vm.data.reference)}}),_c('div',{staticClass:"credit-card-payment-item__column tw-my-auto tw-mx-0 credit-card-payment-item__price tw-flex-1 tw-text-right tw-pr-3 tw-font-medium"},[_c('span',{staticClass:"credit-card-payment-item__price__inline",domProps:{"textContent":_vm._s(_vm.$options.filters.currency(_vm.data.amount, _vm.data.currency, _vm.$i18n.locale))}}),_c('span',{staticClass:"credit-card-payment-item__price__card",domProps:{"textContent":_vm._s(_vm.$t('price_incl_tax', {
          price: _vm.$options.filters.currency(_vm.data.amount, _vm.data.currency, _vm.$i18n.locale)
        }))}})]),_c('div',{staticClass:"credit-card-payment-item__download-column tw-hidden 2sm:tw-flex tw-flex-1 tw-justify-end"},[_c('ctk-btn-file',{staticClass:"tw-mr-4",attrs:{"id":("download-payment-button-" + (_vm.data.reference)),"url":_vm.fileUrl,"popover-title":_vm.$t('app.labels.payment'),"file-name":("payment-" + (_vm.data.reference) + ".pdf")}},[_c('button',{staticClass:"credit-card-payment-item__download btn tw-rounded-full tw-w-10 tw-h-10 tw-p-0",attrs:{"title":_vm.$options.filters.capitalize(_vm.$t('app.buttons.download')),"type":"button"}},[_c('ui-ctk-icon',{attrs:{"name":"save1"}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }