<template>
  <ui-button
    :disabled="isDisabled"
    :loading="$wait.is('requesting payment intent')"
    class="billing-pay-button"
    type="button"
    variant="primary"
    @click="pay"
  >
    <template #left-icon>
      <ui-ctk-icon
        name="credit-card"
        data-test="icon"
      />
    </template>

    <i18n
      :path="getComputedInvoicesTotal <= 0
        ? 'app.buttons.pay'
        : 'app.buttons.pay_amount'"
      tag="span"
      data-test="content"
    >
      <template #amount>
        <span
          class="tw-font-bold"
          v-text="$options.filters.currency(getComputedInvoicesTotal, 'EUR', $i18n.locale)"
        />
      </template>
    </i18n>
  </ui-button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import { showToaster } from '@/services/Toaster'

  /**
   * @module component - BillingPayButton
   */
  export default defineComponent({
    computed: {
      ...mapGetters('billing/credit-card-payment', [
        'getComputedInvoicesTotal'
      ]),
      /**
       * @function isDisabled
       * @returns {boolean}
       */
      isDisabled () {
        return this.getComputedInvoicesTotal <= 0 || this.$wait.is('requesting payment intent')
      }
    },
    methods: {
      ...mapActions('auth', [
        'retrievePaymentSources'
      ]),
      ...mapActions('billing/credit-card-payment', [
        'requestPaymentIntent'
      ]),
      async pay () {
        this.$wait.start('requesting payment intent')

        /**
         * If the payment sources fetching fails for some reason,
         * we do not want to block the payment form.
         */
        try {
          await this.retrievePaymentSources()
        // eslint-disable-next-line no-empty
        } finally {}

        this.requestPaymentIntent()
          .then(() => {
            EventBus.$emit('invoices:dialogs:pay')
          })
          .catch((/** @type {import('axios').AxiosError} */ err) => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              let errorMessage = this.$t('an_error_has_occurred')
              if (data.error && data.error.title) errorMessage = data.error.title

              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => {
            this.$wait.end('requesting payment intent')
          })
      }
    }
  })
</script>
