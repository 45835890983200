<template>
  <div class="billing-header tw-flex tw-justify-between">
    <i18n
      class="billing-header-missions tw-rounded p-3 mb-0 tw-w-full"
      tag="p"
      path="billing.paragraphs.invoices_to_be_paid"
    >
      <template #amount>
        <strong
          v-text="$t('billing.paragraphs.invoices_to_be_paid.amount', {
            amount: formattedAmount
          })"
          class="tw-text-blue-500"
        />
      </template>
    </i18n>
  </div>
</template>

<script>
  /**
   * Generic header component used in bills & payments view
   * @module component - billingHeader
   * @param {number} amount - The sum of all invoices amount
   */
  export default {
    name: 'BillingHeader',
    props: {
      amount: {
        type: Number,
        required: true
      }
    },
    computed: {
      formattedAmount () {
        return this.$options.filters.currency(this.amount, 'EUR', this.$i18n.locale)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .billing-header {
    min-height: 55px;

    &-missions {
      background-color: white;
      border: 1px solid $divider;
      color: $primary-text;

      strong {
        font-weight: 500;
      }
    }
  }

</style>
