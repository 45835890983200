<template>
  <payment-table
    v-bind="$attrs"
    :items="items"
    :columns="columns"
    class="credit-card-payment-table"
    @infinite-scroll="$emit('infinite-scroll')"
  >
    <template
      slot-scope="{ item }"
    >
      <credit-card-payment-item
        :key="item.uuid"
        :data="item"
      />
    </template>
  </payment-table>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import PaymentTable from '@/views/Common/Billing/_subs/Payments/components/PaymentTable/index.vue'
  import CreditCardPaymentItem from './_subs/CreditCardPaymentItem/index.vue'

  /**
   * Shows a list of the payments through a billing table
   * @module component - CarrierPaymentTable
   * @param {Array<any>} items
   */
  export default defineComponent({
    name: 'CarrierPaymentTable',
    components: {
      PaymentTable,
      CreditCardPaymentItem
    },
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        columns: {
          date: 'billing.payments.date',
          reference: 'billing.payments.reference',
          amount: 'billing.payments.amount'
        }
      }
    }
  })
</script>
