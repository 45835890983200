<template>
  <div class="p-3 pt-2 tw-flex invoice-detail-total-billing">
    <div class="tw-flex-1 invoice-detail-total-billing__label">
      {{ title }}
    </div>
    <div
      v-if="billing.invoices_amount && billing.invoices_vat_included_amount"
      class="tw-flex tw-flex-col invoice-detail-total-billing__value"
    >
      <div
        class="text-right"
        data-test="tax-excluded"
      >
        {{ $t('price_excl_tax', {
          price: $options.filters.currency(billing.invoices_amount, currency, $i18n.locale)
        }) }}
      </div>
      <div
        class="fs-18 tw-font-medium text-right"
        data-test="tax-included"
      >
        {{ $t('price_incl_tax', {
          price: $options.filters.currency(billing.invoices_vat_included_amount, currency, $i18n.locale)
        }) }}
      </div>
    </div>
    <div
      v-else
    >
      {{ $t('price_excl_tax', {
        price: $options.filters.currency(billing.price, currency, $i18n.locale)
      }) }}
    </div>
  </div>
</template>

<script>
  /**
   * @module component - InvoiceDetailTotalBilling
   * @param {string} title
   * @param {object} billing
   * @param {string} currency
   */
  export default {
    name: 'InvoiceDetailTotalBilling',
    props: {
      title: {
        type: String,
        required: true
      },
      billing: {
        type: Object,
        required: true
      },
      currency: {
        type: String,
        required: true
      }
    }
  }
</script>
