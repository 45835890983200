<template>
  <div>
    <payment-detail-total
      :total="total"
    />

    <ctk-btn-file
      :id="`download-button-${getCurrentPayment.reference}`"
      :url="fileUrl"
      :popover-title="$t('app.labels.payment_receipt')"
      :file-name="`payment-${getCurrentPayment.reference}.pdf`"
      class="tw-w-full tw-mt-4 tw-mb-2"
      data-test="invoice-button"
    >
      <ui-button
        variant="info"
        class="tw-w-full tw-mt-2"
        data-test="pod-btn"
      >
        {{ $t('billing.buttons.download_payment_receipt') }}
      </ui-button>
    </ctk-btn-file>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'
  import PaymentDetailTotal from '@/views/Common/Billing/_subs/Payments/components/PaymentDetail/_subs/PaymentDetailTotal/index.vue'

  /**
   * @module component - CreditCardPaymentDetailTotal
   */
  export default {
    name: 'CreditCardPaymentDetailTotal',
    components: {
      CtkBtnFile,
      PaymentDetailTotal
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentPayment'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      /**
       * @function fileUrl
       * @returns {string}
       */
      fileUrl () {
        return `v2/companies/${this.getCid}/payments/${this.getCurrentPayment.uuid}/payment-receipt`
      },
      /**
       * Returns the formatted total value
       * @function total
       * @returns {string} total
       */
      total () {
        const payment = this.getCurrentPayment
        return payment
          ? this.$options.filters
            .currency(payment.amount, payment.currency, this.$i18n.locale)
          : null
      }
    }
  }
</script>
