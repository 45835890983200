<template>
  <ctk-list-header
    :title="title"
    :subtitle="subtitle"
    class="invoices-header"
  >
    <ui-button
      :to="{
        name: 'AccountBilling'
      }"
      :rounded="false"
      variant="info"
      data-test="settings"
      class="invoices-header__actions"
    >
      {{ $t('billing.buttons.settings') }}
    </ui-button>
  </ctk-list-header>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader/index.vue'

  /**
   * @module component - InvoicesHeader
   * @param {string} title
   * @param {string} subtitle
   */
  export default defineComponent({
    name: 'InvoicesHeader',
    components: {
      CtkListHeader
    },
    props: {
      subtitle: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .invoices-header {
    &__actions {
      @media only screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      width: 100vw;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
      .ui-select-button {
        display: none;
      }
    }
  }

</style>
