<template>
  <div class="billing-pay-dropdown tw-flex tw-items-center">
    <div class="billing-pay-dropdown__checkbox tw-pl-2">
      <b-form-checkbox
        :checked="isInvoicesSelected"
        :indeterminate="isInvoicesPartiallySelected"
        data-test="checkbox"
        @change="updateCheckbox"
      />
    </div>
    <ui-select-button
      id="pay"
      v-model="item"
      :items="items"
      :text="$t('billing.labels.select_invoices')"
      class="billing-pay-dropdown"
      data-test="select"
      @input="updateDropdown"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'

  import UiSelectButton from '@/components/UI/SelectButton/index.vue'

  /**
   * @module component - BillingPayDropdown
   */
  export default defineComponent({
    components: {
      UiSelectButton
    },
    computed: {
      ...mapGetters('billing', [
        'getInvoicesItems'
      ]),
      ...mapGetters('billing/credit-card-payment', [
        'isInvoicesSelected',
        'isInvoicesPartiallySelected'
      ])
    },
    data () {
      return {
        item: null,
        items: [
          {
            value: 'all',
            text: this.$t('billing.values.select_invoices.all')
          },
          {
            value: 'late',
            text: this.$t('billing.values.select_invoices.late')
          },
          {
            value: 'none',
            text: this.$t('billing.values.select_invoices.none')
          }
        ]
      }
    },
    methods: {
      ...mapActions('billing/credit-card-payment', [
        'selectAllInvoices',
        'setInvoices',
        'unselectAll'
      ]),
      /**
       * @function selectLateInvoices
       */
      selectLateInvoices () {
        const now = moment().startOf('day')
        const invoices = this.getInvoicesItems
          // @ts-ignore
          .filter(invoice => now >= moment(invoice.due_date).startOf('day') && invoice.state !== 'paid')
          // @ts-ignore
          .map(invoice => ({
            uuid: invoice.uuid,
            total: invoice.vat_included_amount
          }))

        this.setInvoices(invoices)
      },
      /**
       * @function updateDropdown
       * @param {any} v
       */
      updateDropdown (v) {
        switch (v[0].value) {
        case 'all':
          this.selectAllInvoices()
          break
        case 'late':
          this.selectLateInvoices()
          break
        case 'none':
          this.unselectAll()
          break
        }
      },
      /**
       * @function updateCheckbox
       * @param {any} v
       */
      updateCheckbox (v) {
        if (v) {
          this.selectAllInvoices()
        } else {
          this.unselectAll()
        }
      }
    }
  })
</script>

<style lang="scss">
.billing-pay-dropdown {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(242, 242, 242, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
}
.billing-pay-dropdown__checkbox {
  position: relative;
}
.billing-pay-dropdown__checkbox::after {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
  height: 1.25rem;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
}
.billing-pay-dropdown__checkbox .custom-checkbox {
  min-height: auto;
}
.billing-pay-dropdown .ui-select-button {
  border: none;
}
.billing-pay-dropdown .ui-select-button-button__content {
  display: block !important;
}
.billing-pay-dropdown .ui-select-button-button {
  border-radius: 0.25rem;
  font-weight: 500;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  height: auto;
  min-height: 38px;
  border: none;
}
.billing-pay-dropdown .ui-select-button-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
.billing-pay-dropdown .ui-select-button-list {
  left: 0;
  right: auto !important;
}
</style>
