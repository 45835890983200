<template>
  <invoice-detail-total
    class="invoice-detail-total-mission"
  >
    <invoice-detail-total-booking
      v-if="getCurrentInvoice.mission"
      :booking="getCurrentInvoice.mission"
      :title="$t('billing.invoice_detail.mission')"
    />

    <invoice-detail-total-billing
      v-if="getCurrentInvoice.mission.billing"
      :title="$t('billing.invoice_detail.mission_amount')"
      :billing="getCurrentInvoice.mission.billing"
      :currency="getCurrentInvoice.currency"
    />
  </invoice-detail-total>
</template>

<script>
  import { mapGetters } from 'vuex'

  import InvoiceDetailTotal from '@/views/Common/Billing/_subs/Invoices/_subs/InvoiceDetail/_subs/InvoiceDetailTotal'
  import InvoiceDetailTotalBooking from '@/views/Common/Billing/_subs/Invoices/_subs/InvoiceDetail/_subs/InvoiceDetailTotal/_subs/InvoiceDetailTotalBooking'
  import InvoiceDetailTotalBilling from '@/views/Common/Billing/_subs/Invoices/_subs/InvoiceDetail/_subs/InvoiceDetailTotal/_subs/InvoiceDetailTotalBilling'

  /**
   * @module component - InvoiceDetailTotalMission
   */
  export default {
    name: 'InvoiceDetailTotalMission',
    components: {
      InvoiceDetailTotal,
      InvoiceDetailTotalBooking,
      InvoiceDetailTotalBilling
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentInvoice'
      ])
    }
  }
</script>
