<template>
  <div
    :class="{
      right
    }"
    class="tw-flex-1 billing-table-head-item tw-rounded tw-truncate tw-relative"
  >
    <span
      class="tw-px-2 tw-uppercase tw-font-medium tw-text-xs tw-text-secondary tw-truncate"
      v-text="name"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - billingTableHeadItem
   * @param {string} name - A translated name of the column
   */
  export default defineComponent({
    name: 'BillingTableHeadItem',
    props: {
      name: {
        type: String,
        required: true
      },
      right: {
        type: Boolean,
        default: false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .billing-table-head-item {
    appearance: none;
    border: none;
    background: transparent;
    outline: none;
    height: 40px;
    line-height: 40px;
    padding: 0;
    transition: box-shadow 200ms, background-color 200ms;
    vertical-align: middle;
    text-align: left;

    & > span {
      vertical-align: middle;
    }

    span {
      margin: auto 0;
    }

    &.right {
      text-align: right;
      justify-content: flex-end;

      span {
        margin-left: auto;
      }
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(black, 0.1);
    }
  }

</style>
