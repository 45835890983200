<template>
  <div
    class="billing tw-flex tw-flex-1"
  >
    <div class="main-container tw-flex tw-flex-1">
      <ctk-side-bar
        :width="350"
        :open="isSidebarOpen"
        class="billing-sidebar"
        @toggle-menu="toggleSidebar"
      >
        <billing-navigation
          @toggle-menu="toggleSidebar"
        />
      </ctk-side-bar>

      <router-view
        class="main-container"
      />

      <!-- Help content is always present as a sidebar -->
      <ctk-side-bar
        v-if="!isUserShipper"
        :width="350"
        :open="true"
        without-close-btn
        class="billing-help-sidebar"
        right
      >
        <billing-help />
      </ctk-side-bar>

      <!-- Sidebar is aways present, with a nested router-view -->
      <ctk-side-bar
        :width="350"
        :open="!!getCurrentPayment || !!getCurrentInvoice || $err.has('no invoice') || $err.has('no payment invoices')"
        :absolute="!isUserShipper"
        without-close-btn
        class="billing-content-sidebar"
        right
      >
        <router-view name="sidebar" />
      </ctk-side-bar>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import CtkSideBar from '@/components/CtkSideBar'
  import BillingNavigation from './components/BillingNavigation'
  import BillingHelp from './components/BillingHelp'

  import store from '@/store'

  export default {
    name: 'Billing',
    metaInfo () {
      return {
        title: this.$t('billing.title')
      }
    },
    components: {
      CtkSideBar,
      BillingNavigation,
      BillingHelp
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        contentSidebar: true,
        helpSidebar: true,
        showDialog: false,
        direction: 'pickup'
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('ui', [
        'isSidebarOpen'
      ]),
      ...mapGetters('billing', [
        'getCurrentPayment',
        'getCurrentInvoice'
      ])
    },
    mounted () {
      // Responsive toggle
      this.toggleOnResize()
      window.addEventListener('resize', this.toggleOnResize)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.toggleOnResize)
    },
    methods: {
      ...mapActions('ui', [
        'toggleSidebar',
        'setSidebarOpen'
      ]),
      /**
       * @function toggleOnResize
       */
      toggleOnResize () {
        const width = document.documentElement.clientWidth
        if (width <= 1350) {
          this.setSidebarOpen(false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .billing {
    position: relative;

    .billing-help-sidebar {
      background: transparent !important;
      box-shadow: none !important;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      .billing-content-sidebar {
        left: auto !important;
        right: 0 !important;
      }

      .billing-help-sidebar {
        display: none;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      .billing-content-sidebar:not(.hide-side-bar) {
        width: 100% !important;
        max-width: 100% !important;
        flex: 1 !important;
      }
    }
  }

</style>
