<template>
  <div class="billing-floating-footer tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-px-4 tw-pt-2 tw-pb-4">
    <billing-pay-button
      class="tw-w-full"
    />
  </div>
</template>

<script>
  import BillingPayButton from '@/views/Common/Billing/_subs/Invoices/components/BillingPayHeader/_subs/BillingPayButton/index.vue'

  /**
   * @module component - BillingFloatingFooter
   */
  export default {
    components: {
      BillingPayButton
    }
  }
</script>

<style lang="scss" scoped>

  .billing-floating-footer {
    background: $light-gray;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  }

</style>
