<template>
  <div class="billing-pay-header tw-flex tw-items-center">
    <div class="billing-pay-header__container tw-flex tw-items-center tw-pb-4 tw-w-full">
      <billing-pay-dropdown
        data-test="dropdown"
      />
      <billing-pay-button
        class="tw-hidden md:tw-flex"
        data-test="pay-button"
      />
    </div>
  </div>
</template>

<script>
  import BillingPayDropdown from './_subs/BillingPayDropdown/index.vue'
  import BillingPayButton from './_subs/BillingPayButton/index.vue'

  /**
   * @module component - BillingPayHeader
   */
  export default {
    components: {
      BillingPayButton,
      BillingPayDropdown
    }
  }
</script>

<style lang="scss">
.billing-pay-header__container {
  background-color: $light-gray;
}
@media (min-width: 770px) {
  .billing-pay-header .billing-pay-dropdown, .billing-pay-header .ui-select-button-button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-width: 0px;
  }
  .billing-pay-header .billing-pay-button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    height: 100%;
  }
}
</style>
