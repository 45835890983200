<template>
  <div class="billing-table tw-w-full tw-flex tw-flex-col">
    <div class="billing-table-header tw-flex-fixed tw-text-center tw-mb-2">
      <slot name="header" />
    </div>

    <transition-group
      v-infinite-scroll="loadMore"
      appear
      name="fade"
      class="billing-table-content tw-relative tw-flex-1"
      infinite-scroll-distance="120"
    >
      <slot name="content" />
      <ctk-loading-layer
        v-if="loadingLayer"
        key="loading-layer"
      />

      <ctk-infinite-loader
        key="infinite-loader"
        class="billing-table-loading"
        :loaded-text="loadingProps.loaded | capitalize"
        :load-more-text="loadingProps.loadMore | capitalize"
        :is-loading="loading"
        :can-load-more="canLoadMore"
        @load-more="loadMore"
      />
    </transition-group>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'
  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader/index.vue'

  /**
   * Generic billing table used by bills & payments
   *
   * @module billingTable
   * @param {boolean} [loading=false] - Specify if the table is loading some content
   * @param {boolean} [canLoadMore=false] - Specify if the table is loading some content
   * @param {object} loadingProps - Set specific loading properties
   * @param {string} loadingProps.loadMore - Load more button wording
   * @param {string} loadingProps.loaded - Everything loaded wording
   * @emits infinite-scroll
   */
  export default defineComponent({
    name: 'BillingTable',
    components: {
      CtkLoadingLayer,
      CtkInfiniteLoader
    },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      canLoadMore: {
        type: Boolean,
        default: false
      },
      loadingProps: {
        type: Object,
        required: true,
        default: null
      },
      loadingLayer: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      loadMore () {
        this.$emit('infinite-scroll')
      }
    }
  })
</script>
