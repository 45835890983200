<template>
  <div class="payments tw-relative tw-flex tw-flex-1">
    <div class="tw-flex tw-flex-1">
      <div class="content tw-flex tw-flex-1 tw-flex-col">
        <!-- Header -->
        <invoices-header
          :title="$t('billing.title.payments')"
          :subtitle="$tc('billing.title.payments.subtitle', getPaymentsMeta.item_count, {
            count: $n(getPaymentsMeta.item_count)
          })"
          class="flex-fixed payments__header mb-2"
        />

        <!-- Content -->
        <transition name="fade">
          <div
            v-if="!$wait.is('loading payments')"
            class="tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto"
          >
            <carrier-payment-table
              v-if="getPaymentsMeta.item_count > 0"
              ref="table"
              :items="getPaymentsItems"
              :loading="$wait.is('loading infinite payments')"
              :loading-props="{
                loadMore: $t('billing.load_more_payments'),
                loaded: $t('billing.all_payments_loaded')
              }"
              :can-load-more="getPaymentsMeta.item_count && canRetrieveMore && !$wait.is('loading infinite payments')"
              class="tw-flex-1"
              @infinite-scroll="retrieveMore"
            />
            <ctk-empty-state
              v-else
              :title="$t('billing.no_payments')"
            />
          </div>
        </transition>
      </div>
    </div>

    <ctk-loading-layer
      v-if="$wait.is('loading payments')"
      :background="false"
    >
      {{ $t('billing.loading_payments') }}
    </ctk-loading-layer>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { Payment } from '@/resources'

  import CtkEmptyState from '@/components/CtkEmptyState'
  import CtkLoadingLayer from '@/components/CtkLoadingLayer'

  import CarrierPaymentTable from './components/CarrierPaymentTable'
  import InvoicesHeader from '@/views/Common/Billing/_subs/Invoices/_subs/InvoicesHeader'

  import store from '@/store'

  /**
   * @module view - payments
   */
  export default {
    name: 'Payments',
    components: {
      CtkEmptyState,
      CtkLoadingLayer,
      InvoicesHeader,
      CarrierPaymentTable
    },
    data () {
      return {
        pagination: 1,
        sidebar: true
      }
    },
    beforeRouteEnter (to, from, next) {
      store.commit('wait/START', 'loading payments')

      if (store.getters.isUserShipper) {
        next({
          name: 'Dashboard'
        })
        return
      }

      store.dispatch('billing/retrieveInvoicesMetrics')
      store.dispatch('billing/retrievePaymentsMetrics')
      next()
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('billing', [
        'getPaymentsMeta',
        'getPaymentsItems'
      ]),
      /**
       * @function getCurrentPayment
       */
      getCurrentPayment () {
        return this.$route.params.uuid
      },
      /**
       * Returns true if we can retrive more payments
       * @function canRetrieveMore
       * @returns {boolean} retrieveMore
       */
      canRetrieveMore () {
        const { pagination } = this.getPaymentsMeta
        return pagination.current_page < pagination.page_count
      }
    },
    watch: {
      getCurrentPayment () {
        this.updateInvoices()
      }
    },
    mounted () {
      this.updatePayments()
        .then(() => {
          this.updateInvoices()
        })
    },
    methods: {
      ...mapActions('billing', [
        'retrievePayments',
        'retrieveMorePayments',
        'setCurrentPaymentInvoices'
      ]),
      /**
       * @function retriveMore
       */
      retrieveMore () {
        if (this.canRetrieveMore) {
          this.$wait.start('loading infinite payments')
          this.retrieveMorePayments()
            .finally(() => {
              this.$wait.end('loading infinite payments')
            })
        }
      },
      /**
       * @function updateInvoices
       */
      updateInvoices () {
        if (this.getCurrentPayment) {
          this.$wait.start('fetching payment invoices')
          this.$err.hide('no payment invoices')

          Payment.invoices({
            cid: this.getCid,
            pid: this.getCurrentPayment
          })
            .then((res) => {
              this.setCurrentPaymentInvoices(res.data.items)
            })
            .catch(() => {
              this.$err.show('no payment invoices')
            })
            .finally(() => {
              this.$wait.end('fetching payment invoices')
            })
        }
      },
      /**
       * @function updatePayments
       */
      updatePayments (loader = 'loading payments') {
        this.$wait.start(loader)
        return this.retrievePayments({
          page: 1
        })
          .finally(() => {
            this.$wait.end(loader)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .payments {
    .invoices-header,
    .payment-table {
      padding: 0 30px 0 53px;
    }

    .invoices-header::after {
      left: 53px;
      width: calc(100% - 83px);
    }

    .content {
      position: relative;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      .payment-table {
        padding: 0 16px;
      }

      &-header {
        padding: 0 16px 0 39px;

        &::after {
          left: 39px;
          width: calc(100% - 55px);
        }
      }
    }
  }

</style>
