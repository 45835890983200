<template>
  <UiButton
    v-b-tooltip.hover
    :title="$t('close') | capitalize"
    variant="link"
    class="close-button"
    @click="close"
  >
    <template #left-icon>
      <ui-ctk-icon
        name="close"
        data-test="icon"
      />
    </template>
  </UiButton>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  /**
   * @module closeButton
   */
  export default defineComponent({
    name: 'CloseButton',
    computed: {
      ...mapGetters('billing', [
        'getCurrentInvoice'
      ])
    },
    methods: {
      /**
       * Close the sidebar by redirecting to the payments list
       * @function close
       */
      close () {
        let params = {
          stateUuid: 'pending'
        }

        if (this.getCurrentInvoice && this.getCurrentInvoice.state) {
          const { state } = this.getCurrentInvoice
          params = {
            stateUuid: state === 'waiting_pod' ? 'pending' : state
          }
        }

        this.$err.hide('no invoice')
        this.$router.push({ name: 'Invoices', params })
          .catch(() => {})
      }
    }
  })
</script>
