<template>
  <div
    class="p-3 pb-2 invoice-detail-total-booking"
  >
    <div class="tw-flex">
      <div class="tw-flex-1 invoice-detail-total-booking__label">
        {{ title }}
      </div>
      <div class="tw-flex tw-flex-col invoice-detail-total-booking__value">
        <div
          class="text-right"
          data-test="reference"
        >
          {{ booking.reference }}
        </div>
        <div
          v-if="booking.pickup && booking.delivery"
          class="fs-18 tw-font-medium text-right"
          data-test="postal-codes"
        >
          {{ booking.pickup.address.postal_code }} > {{ booking.delivery.address.postal_code }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - InvoiceDetailTotalBooking
   * @param {object} booking
   * @param {string} title
   */
  export default {
    name: 'InvoiceDetailTotalBooking',
    props: {
      booking: {
        type: Object,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    }
  }
</script>
