<template>
  <ui-button
    :title="$t('close') | capitalize"
    class="payment-detail-header-close-button"
    variant="link"
    @click="close"
  >
    <template #left-icon>
      <ui-ctk-icon
        name="close"
        data-test="icon"
      />
    </template>
  </ui-button>
</template>

<script>
  /**
   * @module PaymentDetailHeaderCloseButton
   * @param {object} to
   */
  export default {
    name: 'PaymentDetailHeaderCloseButton',
    props: {
      to: {
        type: Object,
        required: true
      }
    },
    methods: {
      /**
       * Close the sidebar by redirecting to the payments list
       * @function close
       */
      close () {
        // @ts-ignore
        this.$err.hide('no payment invoices')
        // @ts-ignore
        this.$router.push(this.to)
          .catch(() => {})
      }
    }
  }
</script>
