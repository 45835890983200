<template>
  <payment-detail
    class="credit-card-payment-detail"
  >
    <template #header>
      <payment-detail-header
        :to="{
          name: 'CreditCardPayments'
        }"
        class="flex-fixed"
        data-test="header"
      />
    </template>

    <main
      class="tw-flex-1 tw-overflow-y-auto tw-relative"
      data-test="contentfull"
    >
      <div class="tw-p-4">
        <credit-card-payment-detail-total
          data-test="total"
        />
      </div>
      <payment-detail-invoices
        data-test="invoices"
      />
      <ctk-loading-layer
        v-if="$wait.is('fetching payment invoices')"
        data-test="loader"
      >
        {{ $t('billing.loading_invoices') }}
      </ctk-loading-layer>
    </main>

    <template #empty-state>
      <ctk-empty-state
        :title="$t('billing.title.no_payment')"
        class="tw-flex-1"
        sidebar
        data-test="empty-state"
      />
    </template>
  </payment-detail>
</template>

<script>
  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'
  import CtkEmptyState from '@/components/CtkEmptyState/index.vue'

  import PaymentDetail from '@/views/Common/Billing/_subs/Payments/components/PaymentDetail/index.vue'
  import PaymentDetailHeader from '@/views/Common/Billing/_subs/Payments/components/PaymentDetail/_subs/PaymentDetailHeader/index.vue'
  import PaymentDetailInvoices from '@/views/Common/Billing/_subs/Payments/components/PaymentDetail/_subs/PaymentDetailInvoices/index.vue'

  import CreditCardPaymentDetailTotal from './_subs/CreditCardPaymentDetailTotal/index.vue'

  /**
   * @module component - CreditCardPaymentDetail
   */
  export default {
    name: 'CreditCardPaymentDetail',
    components: {
      CtkLoadingLayer,
      CtkEmptyState,
      PaymentDetail,
      PaymentDetailHeader,
      CreditCardPaymentDetailTotal,
      PaymentDetailInvoices
    }
  }
</script>
