<template>
  <div class="invoice-item-alert">
    <ui-ctk-icon
      :id="`icon-warning-${invoice.uuid}`"
      class="tw-flex tw-text-yellow-500"
      name="warning"
      data-test="icon"
    />
    <b-tooltip
      v-if="hasTooltip"
      :target="`icon-warning-${invoice.uuid}`"
    >
      {{ $t('billing.paragraphs.missing_pod') }}
    </b-tooltip>
  </div>
</template>

<script>
  /**
   * @module component - InvoiceItemAlert
   * @param {object} invoice
   * @param {boolean} [hasTooltip=false]
   */
  export default {
    name: 'InvoiceItemAlert',
    props: {
      invoice: {
        type: Object,
        required: true
      },
      hasTooltip: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .invoice-item-alert {
    .ctk-font {
      font-size: 28px;
      vertical-align: middle;
      margin: auto 0;

      &::before {
        margin: auto;
      }
    }
  }

</style>
