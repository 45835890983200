<template>
  <span
    :class="{
      'tw-bg-yellow-500': color === 'yellow',
      'tw-bg-teal-500': color === 'teal',
      'tw-bg-blue-500': color === 'blue',
      'tw-bg-purple-500': color === 'purple'
    }"
    class="ui-chip tw-inline-block tw-text-white rounded tw-px-2 tw-font-medium tw-truncate"
  >
    <slot />
  </span>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - UiChip
   * @param {string} color
   */
  export default defineComponent({
    props: {
      color: {
        type: String,
        required: true
      }
    }
  })
</script>
