<template>
  <ctk-item
    :to="{
      name: 'CreditCardPayments',
      params: {
        uuid: data.uuid
      }
    }"
    class="credit-card-payment-item tw-flex tw-justify-between tw-items-center tw-mb-2 tw-rounded"
    touchable-type="router-link"
  >
    <div class="tw-flex-col 2sm:tw-flex-row tw-flex 2sm:tw-items-center tw-w-full tw-my-auto">
      <div
        class="credit-card-payment-item__column tw-my-auto tw-mx-0 credit-card-payment-item__issue-date tw-flex-1 tw-pl-4 tw-mt-2 md:tw-mt-auto tw-flex tw-items-center"
        data-test="issue-date"
      >
        <UiBadge
          class="tw-my-auto"
          color="teal"
        >
          <span
            class="credit-card-payment-item__issue-date__inline"
            v-text="issue"
          />
          <span
            class="credit-card-payment-item__issue-date__card"
            v-text="$t('billing.values.issue_date', {
              date: issue
            })"
          />
        </UiBadge>
      </div>

      <div
        class="credit-card-payment-item__column tw-my-auto tw-mx-0 credit-card-payment-item__reference tw-select-all tw-flex-1 tw-pl-2"
        v-text="data.reference"
        data-test="reference"
      />

      <div
        class="credit-card-payment-item__column tw-my-auto tw-mx-0 credit-card-payment-item__price tw-flex-1 tw-text-right tw-pr-3 tw-font-medium"
        data-test="amount"
      >
        <span
          class="credit-card-payment-item__price__inline"
          v-text="$options.filters.currency(data.amount, data.currency, $i18n.locale)"
          data-test="amount-inline"
        />
        <span
          class="credit-card-payment-item__price__card"
          v-text="$t('price_incl_tax', {
            price: $options.filters.currency(data.amount, data.currency, $i18n.locale)
          })"
          data-test="amount-card"
        />
      </div>

      <!-- Download payment -->
      <div
        class="credit-card-payment-item__download-column tw-hidden 2sm:tw-flex tw-flex-1 tw-justify-end"
      >
        <ctk-btn-file
          :id="`download-payment-button-${data.reference}`"
          :url="fileUrl"
          :popover-title="$t('app.labels.payment')"
          :file-name="`payment-${data.reference}.pdf`"
          class="tw-mr-4"
          data-test="payment-button"
        >
          <button
            :title="$options.filters.capitalize($t('app.buttons.download'))"
            type="button"
            class="credit-card-payment-item__download btn tw-rounded-full tw-w-10 tw-h-10 tw-p-0"

            data-test="pod-btn"
          >
            <ui-ctk-icon
              name="save1"
              data-test="icon"
            />
          </button>
        </ctk-btn-file>
      </div>
    </div>
  </ctk-item>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import UiChip from '@/components/UI/Chip/index.vue'
  import CtkItem from '@/components/CtkItem/index.vue'
  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'

  /**
   * @module component - CreditCardPaymentItem
   * @param {object} data - Payment item data
   * @param {string} data.uuid - Unique ID
   * @param {string} data.reference - Unique reference ID
   * @param {string} data.issue_date - Issue date
   * @param {number} data.amount - The payment total amount
   * @param {string} data.currency - The payment total amount currency
   */
  export default defineComponent({
    name: 'CreditCardPaymentItem',
    components: {
      CtkItem,
      UiChip,
      CtkBtnFile
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      /**
       * Returns the issued formatted date
       * @function issue
       * @returns {string} date
       */
      issue () {
        return this.$moment(this.data.date).format('LL')
      },
      /**
       * @function fileUrl
       * @returns {string}
       */
      fileUrl () {
        return `v2/companies/${this.getCid}/payments/${this.data.uuid}/payment-receipt`
      }
    }
  })
</script>

<style lang="scss" scoped>
.credit-card-payment-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  min-height: 35px;
}
.credit-card-payment-item::before {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 207, 181, var(--tw-bg-opacity));
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  content: '';
  width: 5px;
}
.credit-card-payment-item.active, .credit-card-payment-item.router-link-active {
  background-color: $divider !important;
  box-shadow: 0 1px 3px rgba(black, 0.2) !important;
}
.credit-card-payment-item .credit-card-payment-item__price__card, .credit-card-payment-item .credit-card-payment-item__issue-date__card {
  display: none;
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .credit-card-payment-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px 8px 27px;
  }
  .credit-card-payment-item .credit-card-payment-item__reference, .credit-card-payment-item .credit-card-payment-item__issue-date {
    padding-left: 0 !important;
    margin-bottom: 8px;
  }
  .credit-card-payment-item .credit-card-payment-item__price__card, .credit-card-payment-item .credit-card-payment-item__issue-date__card {
    display: inline;
  }
  .credit-card-payment-item .credit-card-payment-item__price__inline, .credit-card-payment-item .credit-card-payment-item__issue-date__inline {
    display: none;
  }
}
.credit-card-payment-item__download-column {
  flex: 0.8;
}
.credit-card-payment-item__download {
  background: transparent;
}
.credit-card-payment-item__download:hover {
  background: $light-gray;
}
.credit-card-payment-item__download:focus:not(:focus-visible) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
</style>
