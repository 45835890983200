<template>
  <div class="billing-help-section">
    <h2 class="fs-18 billing-help-section-title tw-text-blue-500">
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script>
  /**
   * @module component - billingHelpSection
   * @param {string} title
   */
  export default {
    name: 'BillingHelpSection',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .billing-help-section {
    &:not(:first-child) {
      border-top: 1px solid #E8EAEC;
      padding-top: 16px;
      margin-bottom: 16px;
    }

    &-title {
      text-transform: uppercase;
    }
  }

</style>
