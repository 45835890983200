<template>
  <div class="tw-flex billing-help-email tw-rounded px-3 py-2">
    <a
      :href="`mailto:${email}`"
      class="billing-help-email-content"
    >
      {{ email }}
    </a>
  </div>
</template>

<script>
  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component - billingHelpEmail
   */
  export default {
    name: 'BillingHelpEmail',
    data () {
      return {
        email: chronoinfos.emails.billing
      }
    }
  }
</script>

<style lang="scss" scoped>

  .billing-help-email {
    background-color: #FCFCFC;
    border: 1px solid #E0E0E0;

    .email-container-icon {
      user-select: none;
    }

    &-content {
      margin-bottom: 0;
      text-decoration: none;
      color: $primary-text;
    }
  }

</style>
