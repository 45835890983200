<template>
  <div class="tw-rounded mb-3 billing-help-address">
    <h3 class="fs-16 tw-font-medium billing-help-address-title">
      {{ $t('billing.help.how-it-works.invoice_to') }}
    </h3>
    <address class="billing-help-address-content">
      Chronotruck<br>
      {{ address }}<br>
      {{ postalCode }}, {{ city }}<br>
      {{ vat }}<br>
    </address>
  </div>
</template>

<script>
  import chronoinfos, { location } from '@/../config/chronoinfos'

  /**
   * @module component - billingHelpAddress
   */
  export default {
    name: 'BillingHelpAddress',
    data () {
      return {
        address: location.address,
        postalCode: location.postalCode,
        city: location.city,
        vat: chronoinfos.VAT
      }
    }
  }
</script>

<style lang="scss" scoped>

  .billing-help-address {
    background-color: #FCFCFC;
    border: 1px solid #E0E0E0;
    padding: 16px;

    &-content {
      margin-bottom: 0;
    }
  }

</style>
