<template>
  <ctk-item
    :class="{
      error: hasError,
      paid: data.state === 'paid',
      credit: data.type && data.type.key === 'AFC'
    }"
    :touchable="!isUserShipper"
    class="invoice-item tw-flex tw-items-center tw-mb-2"
    touchable-type="div"
    @click.native="select"
  >
    <invoice-item-card
      :data="data"
      :has-error="hasError"
      :selectable="selectable"
      :checked="checked"
      @update:checked="check"
    />

    <invoice-item-inline
      :selectable="selectable"
      :data="data"
      :checked="checked"
      :has-error="hasError"
      :has-error-tooltip="hasErrorTooltip"
      @update:checked="check"
    />
  </ctk-item>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkItem from '@/components/CtkItem/index.vue'
  import InvoiceItemCard from './_subs/InvoiceItemCard/index.vue'
  import InvoiceItemInline from './_subs/InvoiceItemInline/index.vue'

  /**
   * @module component - invoiceItem
   * @param {object} data
   * @param {boolean} [checked=false]
   * @param {boolean} [selectable=false]
   * @param {boolean} [hasError=false]
   * @param {boolean} [hasErrorTooltip=true]
   * @emits update:checked
   */
  export default defineComponent({
    name: 'InvoiceItem',
    components: {
      CtkItem,
      InvoiceItemCard,
      InvoiceItemInline
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      checked: {
        type: Boolean,
        default: false
      },
      selectable: {
        type: Boolean,
        default: false
      },
      hasError: {
        type: Boolean,
        default: false
      },
      hasErrorTooltip: {
        type: Boolean,
        default: false
      }
    },
    setup (props, { emit }) {
      const { state: checkedValue } = useModelGetterSetter(props, 'checked')

      const check = (/** @type {boolean} */ v) => emit('update:checked', v)

      return {
        checkedValue,
        check
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ])
    },
    methods: {
      /**
       * @function select
       */
      select (e) {
        /**
         * Handle the case where the user presses the checkbox (if any).
         * This should not trigger the invoice open behaviour.
         */
        if (e.target.closest('.invoice-checkbox')) {
          e.stopPropagation()
          return
        }

        /**
         * Ignore the clicking behaviour if the user is a shipper
         * as we don't want to open the invoice detail for this user type.
         * Since we're keeping the behaviour for carriers, we cannot remove
         * the component entirely.
         */
        if (this.isUserShipper) return

        this.$router.push({
          name: 'Invoices',
          params: {
            stateUuid: this.data.uuid
          }
        }).catch(() => {})
      }
    }
  })
</script>

<style lang="scss" scoped>
.invoice-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  transform: scale(1, 1);
}
.invoice-item.active, .invoice-item.router-link-exact-active {
  box-shadow: 0 1px 3px rgba(black, 0.2) !important;
  background-color: $divider !important;
}
.invoice-item.error, .invoice-item.paid, .invoice-item.credit {
  position: relative;
}
.invoice-item.error::before, .invoice-item.paid::before, .invoice-item.credit::before {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  content: '';
  width: 5px;
}
.invoice-item.error {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 229, 203, var(--tw-bg-opacity));
}
.invoice-item.error::before {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 166, 35, var(--tw-bg-opacity));
}
.invoice-item.paid::before {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 207, 181, var(--tw-bg-opacity));
}
.invoice-item.credit::before {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 67, 185, var(--tw-bg-opacity));
}
.invoice-item.ctk-item--touchable:hover.error, .invoice-item.ctk-item--touchable.error.active, .invoice-item.ctk-item--touchable.error.router-link-exact-active {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 218, 192, var(--tw-bg-opacity));
}
.invoice-item .invoice-item-card {
  display: none;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .invoice-item {
    padding: 8px 20px 10px 32px;
  }
  .invoice-item .invoice-item-card {
    display: block;
  }
  .invoice-item .invoice-item-inline {
    display: none;
  }
}
</style>
