<template>
  <billing-table
    v-bind="$attrs"
    class="bill-table"
    @infinite-scroll="scroll"
  >
    <template #header>
      <billing-table-head
        class="tw-flex"
      >
        <div
          v-if="selectable"
          class="flex-fixed empty-col"
        />
        <div class="flex-fixed empty-col" />
        <billing-table-head-item
          v-for="(column, k) in columns"
          :key="k"
          :right="['vat_included_amount', 'amount'].includes(column)"
          :name="$t(`billing.invoices.${column}`)"
          :column="column"
          :class="{
            'small-sized': ['issue_date', 'due_date', 'vat_included_amount', 'amount'].includes(column)
          }"
        />
        <billing-table-head-item
          v-if="isUserShipper"
          :name="''"
          class="small-sized"
        />
      </billing-table-head>
    </template>

    <template #content>
      <bill-item
        v-for="item in items"
        :key="item.uuid"
        :data="item"
        :has-error="item.state === 'waiting_pod' || isLate(item)"
        :has-error-tooltip="!isUserShipper"
        :checked="item.checked"
        :selectable="selectable"
        @update:checked="toggleInvoice({
          uuid: item.uuid,
          total: item.vat_included_amount
        })"
        @click.native="selectInvoice(item)"
      />
    </template>
  </billing-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import BillingTable from '@/views/Common/Billing/components/BillingTable/index.vue'
  import BillingTableHead from '@/views/Common/Billing/components/BillingTable/_subs/BillingTableHead/index.vue'
  import BillingTableHeadItem from '@/views/Common/Billing/components/BillingTable/_subs/BillingTableHead/_subs/BillingTableHeadItem/index.vue'
  import BillItem from './_subs/BillItem/index.vue'

  /**
   * @module component - billTable
   * @param {Array<any>} items
   */
  export default defineComponent({
    name: 'BillTable',
    components: {
      BillItem,
      BillingTable,
      BillingTableHead,
      BillingTableHeadItem
    },
    inheritAttrs: true,
    props: {
      items: {
        type: Array,
        required: true
      },
      selectable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      /**
       * @function columns
       * @returns {Array<string>}
       */
      columns () {
        return this.isUserShipper
          ? [
            'due_date',
            'issue_date',
            'reference',
            'amount',
            'vat_included_amount'
          ]
          : [
            'due_date',
            'issue_date',
            'reference',
            'mission_reference',
            'vat_included_amount'
          ]
      }
    },
    methods: {
      ...mapActions('billing/credit-card-payment', [
        'toggleInvoice'
      ]),
      /**
       * @function selectInvoice
       * @param {any} item
       */
      selectInvoice (item) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Invoices', 'Clicked', item.uuid)
        }
      },
      scroll () {
        this.$emit('infinite-scroll')
      },
      /**
       * For a shipper, returns true if the invoice payment is late
       * @function isLate
       * @param {any} invoice
       * @returns {boolean}
       */
      isLate (invoice) {
        if (invoice.type.key === 'AFC') return false

        return this.isUserShipper &&
          this.$moment().startOf('day') >= this.$moment(invoice.due_date).startOf('day') &&
          invoice.state !== 'paid'
      }
    }
  })
</script>

<style lang="scss" scoped>

  .bill-table {
    .billing-table-header .tw-text-gray-700:last-child {
      text-align: right;
      padding-right: 16px;
    }

    .billing-table-head-item {
      &.small-sized {
        flex: 0.8;
      }
    }

    .empty-col {
      width: 32px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .billing-table-head {
        display: none;
      }
    }
  }

</style>
