<template>
  <div class="ui-select-button tw-relative">
    <ui-select-button-button
      ref="button"
      v-model="open"
      :has-dot="hasDot"
      :icon="icon"
      @input="buttonInput"
      @keyup.native="checkShow"
    >
      {{ text }}
    </ui-select-button-button>

    <transition name="slide">
      <ui-select-button-list
        ref="list"
        v-if="open"
        v-model="item"
        :open.sync="open"
        :id="id"
        :items="items"
        :multiple="multiple"
        @re-focus="refocus"
      />
    </transition>
  </div>
</template>

<script>
  import { defineComponent, ref, watch, nextTick } from '@vue/composition-api'

  import { KEYCODES } from '@/composables/constants'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import UiSelectButtonList from './_subs/UiSelectButtonList/index.vue'
  import UiSelectButtonButton from './_subs/UiSelectButtonButton/index.vue'

  /**
   * @module component - UiSelectButton
   * @param {string} id
   * @param {Array<object>} items
   * @param {string} text
   * @param {boolean} [multiple=false]
   * @param {boolean} [hasDot=false]
   */
  export default defineComponent({
    name: 'UiSelectButton',
    components: {
      UiSelectButtonButton,
      UiSelectButtonList
    },
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      icon: {
        type: String,
        default: null
      },
      value: {
        type: [String, Number, Array],
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        required: true
      },
      hasDot: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: item } = useModelGetterSetter(props, 'value')
      /** @type {import('@vue/composition-api').Ref<HTMLElement|null>} */
      const list = ref(null)
      /** @type {import('@vue/composition-api').Ref<HTMLElement|null>} */
      const button = ref(null)
      const open = ref(false)

      watch(item, () => {
        open.value = false
      })

      /**
       * @function checkShow
       * @param {KeyboardEvent} e
       */
      function checkShow (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.UP:
        case KEYCODES.DOWN:
          e.preventDefault()
          show()
          break
        }
      }

      /**
       * @function show
       * @returns {Promise<any>}
       */
      async function show () {
        open.value = true
        await nextTick()
        if (list.value) {
          list.value.focus()
        }
      }

      function refocus () {
        if (button.value) button.value.focus()
      }

      /**
       * @function buttonInput
       * @param {boolean} v
       */
      const buttonInput = v => v && show()

      return {
        buttonInput,
        checkShow,
        refocus,
        show,
        list,
        item,
        open
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ui-select-button-list {
    right: 0;
  }

</style>
