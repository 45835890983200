<template>
  <invoice-detail-total
    class="invoice-detail-total-shipment"
  >
    <invoice-detail-total-booking
      v-if="getCurrentInvoice.shipment"
      :booking="getCurrentInvoice.shipment"
      :title="$t('billing.invoice_detail.shipment')"
    />
  </invoice-detail-total>
</template>

<script>
  import { mapGetters } from 'vuex'

  import InvoiceDetailTotal from '@/views/Common/Billing/_subs/Invoices/_subs/InvoiceDetail/_subs/InvoiceDetailTotal/index.vue'
  import InvoiceDetailTotalBooking from '@/views/Common/Billing/_subs/Invoices/_subs/InvoiceDetail/_subs/InvoiceDetailTotal/_subs/InvoiceDetailTotalBooking/index.vue'

  /**
   * @module component - InvoiceDetailTotalShipment
   */
  export default {
    name: 'InvoiceDetailTotalShipment',
    components: {
      InvoiceDetailTotal,
      InvoiceDetailTotalBooking
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentInvoice'
      ])
    }
  }
</script>
