<template>
  <billing-table
    v-bind="$attrs"
    class="payment-table"
    @infinite-scroll="scroll"
  >
    <template #header>
      <billing-table-head
        class="tw-hidden 2sm:tw-flex"
      >
        <billing-table-head-item
          v-for="(column, k) in columns"
          :key="k"
          :right="['amount'].includes(k)"
          :name="$t(column)"
        />

        <!-- Adding an empty column for shippers, to make space for a download button -->
        <billing-table-head-item
          v-if="isUserShipper"
          :name="''"
          class="small-sized"
        />
      </billing-table-head>
    </template>

    <template #content>
      <template
        v-for="item in items"
      >
        <slot
          :item="item"
        />
      </template>
    </template>
  </billing-table>
</template>

<script>
  import { mapGetters } from 'vuex'

  import BillingTable from '@/views/Common/Billing/components/BillingTable/index.vue'
  import BillingTableHead from '@/views/Common/Billing/components/BillingTable/_subs/BillingTableHead/index.vue'
  import BillingTableHeadItem from '@/views/Common/Billing/components/BillingTable/_subs/BillingTableHead/_subs/BillingTableHeadItem/index.vue'

  /**
   * Shows a list of the payments through a billing table
   * @module component - PaymentTable
   * @param {Array<any>} items
   * @param {object} columns
   */
  export default {
    name: 'PaymentTable',
    components: {
      BillingTable,
      BillingTableHead,
      BillingTableHeadItem
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      columns: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ])
    },
    methods: {
      scroll () {
        this.$emit('infinite-scroll')
      }
    }
  }
</script>

<style lang="scss" scoped>
.payment-table {
  padding: 0 30px;
}
.payment-table .billing-table-head-item.small-sized {
  flex: 0.8;
}
.payment-table .billing-table-header .tw-text-gray-700:last-child {
  padding-right: 1rem;
  text-align: right;
}
</style>
