<template>
  <ctk-item
    :to="{
      name: 'Payments',
      params: {
        uuid: data.uuid
      }
    }"
    class="payment-item tw-flex tw-justify-between tw-items-center tw-mb-2 tw-rounded"
    touchable-type="router-link"
  >
    <div class="tw-flex tw-flex-col 2sm:tw-flex-row tw-items-start 2sm:tw-items-center tw-w-full tw-my-auto">
      <div class="payment-item-column tw-my-auto tw-mx-0 payment-item__reference tw-select-all tw-flex-1 tw-pl-2">
        {{ data.reference }}
      </div>
      <div class="payment-item-column tw-my-auto tw-mx-0 payment-item__issue-date tw-flex-1 tw-pl-2">
        <span class="payment-item__issue-date__inline">
          {{ issue }}
        </span>
        <span class="payment-item__issue-date__card">
          {{ $t('billing.values.issue_date', {
            date: issue
          }) }}
        </span>
      </div>
      <div class="payment-item-column tw-my-auto tw-mx-0 payment-item__price tw-flex-1 tw-text-right tw-pr-3 tw-font-medium">
        <span class="payment-item__price__inline">
          {{ data.amount | currency(data.currency, $i18n.locale) }}
        </span>
        <span class="payment-item__price__card">
          {{ $t('price_incl_tax', {
            price: $options.filters.currency(data.amount, data.currency, $i18n.locale)
          }) }}
        </span>
      </div>
    </div>
  </ctk-item>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkItem from '@/components/CtkItem/index.vue'

  /**
   * @module component - paymentItem
   * @param {object} data - Payment item data
   * @param {string} data.uuid - Unique ID
   * @param {string} data.reference - Unique reference ID
   * @param {string} data.issue_date - Issue date
   * @param {number} data.amount - The payment total amount
   * @param {string} data.currency - The payment total amount currency
   */
  export default defineComponent({
    name: 'PaymentItem',
    components: {
      CtkItem
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns the issued formatted date
       * @function issue
       * @returns {string} date
       */
      issue () {
        return this.$moment(this.data.issue_date).format('LL')
      }
    }
  })
</script>

<style lang="scss" scoped>
.payment-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border: 1px solid $divider;
  min-height: 35px;
}
.payment-item.active, .payment-item.router-link-active {
  background-color: $divider !important;
  box-shadow: 0 1px 3px rgba(black, 0.2) !important;
}
.payment-item .payment-item__price__card, .payment-item .payment-item__issue-date__card {
  display: none;
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .payment-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px 8px 27px;
  }
  .payment-item .payment-item__reference, .payment-item .payment-item__issue-date {
    padding-left: 0 !important;
    margin-bottom: 8px;
  }
  .payment-item .payment-item__price__card, .payment-item .payment-item__issue-date__card {
    display: inline;
  }
  .payment-item .payment-item__price__inline, .payment-item .payment-item__issue-date__inline {
    display: none;
  }
}
</style>
