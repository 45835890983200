var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoices tw-flex tw-flex-1",class:{
    'tw-pb-16 md:tw-pb-0': _vm.isUserShipper
  }},[_c('div',{staticClass:"tw-flex tw-flex-col tw-flex-1"},[(_vm.getInvoicesMetrics && _vm.getInvoicesMetrics.counts)?_c('invoices-header',{staticClass:"flex-fixed mb-2",attrs:{"title":_vm.title,"subtitle":_vm.subtitle}}):_vm._e(),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.retrieveMoreMobile),expression:"retrieveMoreMobile"}],staticClass:"tw-w-screen 2sm:tw-w-auto content tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto"},[_c('div',{staticClass:"invoices__subheader"},[(!_vm.isUserShipper && _vm.hasBillingHeader && _vm.getInvoicesMeta.amounts && _vm.getInvoicesMeta.amounts.pending)?_c('billing-header',{staticClass:"flex-fixed",attrs:{"amount":_vm.getInvoicesMeta.amounts.pending}}):_vm._e(),(_vm.isUserShipper && _vm.getInvoicesMetrics && _vm.getInvoicesMetrics.counts && _vm.state === 'pending')?_c('billing-invoice-amounts-header',{staticClass:"mb-1"}):_vm._e()],1),(_vm.hasPaySection)?_c('billing-pay-header',{staticClass:"invoices__subheader tw-mt-6 md:tw-mt-12 tw-top-0"}):_vm._e(),(!_vm.$wait.is('loading invoices'))?_c('transition',{attrs:{"name":"fade"}},[(_vm.count > 0)?_c('bill-table',{ref:"table",staticClass:"tw-flex-1 tw-mt-1",class:{
            'has-pay-header': _vm.hasPaySection
          },attrs:{"items":_vm.items,"loading-layer":false,"loading":_vm.$wait.is('loading more invoices'),"loading-props":{
            loadMore: _vm.$t('billing.load_more_invoices'),
            loaded: _vm.$t('billing.all_invoices_loaded')
          },"selectable":_vm.hasPaySection,"can-load-more":_vm.count && _vm.canRetrieveMore && !_vm.$wait.is('loading more invoices')},on:{"infinite-scroll":_vm.retrieveMore}}):_vm._e(),(_vm.count === 0)?_c('ctk-empty-state',{attrs:{"title":_vm.$t('billing.no_invoices')}}):_vm._e()],1):_vm._e()],1),(_vm.$wait.is('loading invoices'))?_c('ctk-loading-layer',{attrs:{"background":true}},[_vm._v(" "+_vm._s(_vm.$t('billing.loading_invoices'))+" ")]):_vm._e()],1),(_vm.hasPaySection)?_c('billing-floating-footer',{staticClass:"md:tw-hidden"}):_vm._e(),_c('invoices-pay-dialog',{model:{value:(_vm.dialogs.pay),callback:function ($$v) {_vm.$set(_vm.dialogs, "pay", $$v)},expression:"dialogs.pay"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }