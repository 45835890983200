<template>
  <div class="tw-flex tw-flex-1 tw-flex-col billing-navigation tw-overflow-y-auto">
    <ctk-collapse-category-item
      :title="$options.filters.capitalize($t('navigation.billing'))"
      :icon="'copy'"
      data-test="category"
    />

    <ctk-collapse-nav-item
      v-for="(link, k) in links"
      :key="k"
      :to="link.to"
      :title="link.title | capitalize"
      :count="link.count"
      :has-dot="link.hasDot"
      :dot-tooltip="link.dotTooltip"
      :class="{
        'router-link-active': link.active
      }"
      :data-test="link.selector"
      class="billing-navigation__link"
      @click.native="toggleMenu"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import CtkCollapseNavItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseNavItem/index.vue'
  import CtkCollapseCategoryItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseCategoryItem/index.vue'

  /**
   * @module component - billingNavigation
   */
  export default defineComponent({
    name: 'BillingNavigation',
    components: {
      CtkCollapseNavItem,
      CtkCollapseCategoryItem
    },
    computed: {
      ...mapGetters('billing', [
        'getPaymentsMeta',
        'getCurrentInvoice',
        'getInvoicesMetrics',
        'getPaymentsMetrics'
      ]),
      ...mapGetters([
        'isUserShipper'
      ]),
      /**
       * Returns a list of links to show with computed properties.
       * @function links
       * @returns {Array<any>}
       */
      links () {
        const { counts: invoiceCounts } = this.getInvoicesMetrics
        const { counts: paymentCounts } = this.getPaymentsMetrics
        const invoice = this.getCurrentInvoice

        const hasDot = this.isUserShipper && invoiceCounts && invoiceCounts.payment_late > 0

        /**
         * @type {Array<{
         *   selector: string,
         *   title: string,
         *   active?: boolean,
         *   hasDot?: boolean,
         *   dotTooltip?: string | null,
         *   count: number | null,
         *   to: any,
         * }>} links
         */
        const links = [
          {
            selector: 'pending-invoices',
            title: this.$tc(this.isUserShipper
              ? 'billing.title.invoices.state.pending_shipper'
              : 'billing.title.invoices.state.pending', 2),
            to: {
              name: 'Invoices',
              params: {
                stateUuid: 'pending'
              }
            },
            active: invoice && (invoice.state === 'waiting_pod' || invoice.state === 'pending'),
            hasDot,
            dotTooltip: hasDot
              ? this.$tc('billing.values.late_tooltip', invoiceCounts.payment_late, {
                count: invoiceCounts.payment_late
              })
              : null,
            count: invoiceCounts
              ? this.isUserShipper
                ? invoiceCounts.payment_pending
                : invoiceCounts.pending
              : null
          },
          {
            selector: 'paid-invoices',
            title: this.$tc('billing.title.invoices.state.paid', 2),
            to: {
              name: 'Invoices',
              params: {
                stateUuid: 'paid'
              }
            },
            active: invoice && invoice.state === 'paid',
            count: invoiceCounts
              ? invoiceCounts.paid
              : null
          }
        ]

        links.push({
          selector: 'payments',
          title: this.$t(this.isUserShipper
            ? 'billing.navigation.cc_payments'
            : 'billing.navigation.payments'
          ).toString(),
          to: {
            name: this.isUserShipper
              ? 'CreditCardPayments'
              : 'Payments'
          },
          count: paymentCounts
            ? this.isUserShipper
              ? paymentCounts.credit_card
              : paymentCounts.total
            : null
        })

        return links
      }
    },
    methods: {
      /**
       * Called whenever the user clicks on the navigation buttons.
       * Should emit a toggle-menu event only if we're in a mobile viewport.
       * @function toggleMenu
       */
      toggleMenu () {
        const width = document.documentElement.clientWidth
        if (width <= 768) {
          this.$emit('toggle-menu')
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .billing-navigation {
    padding: 30px 28px;

    .ctk-collapse-nav-item {
      padding-left: 30px;
    }
  }

</style>

<style lang="scss">

  .billing-navigation .ctk-collapse-nav-item__badge__icon,
  .hide-side-bar .billing-navigation .ctk-collapse-nav-item__badge__icon {
    background-color: $warning !important;
  }

</style>
