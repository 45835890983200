<template>
  <ctk-dialog
    v-model="dialogValue"
    id="invoices-pay-dialog"
    modal-class="invoices-pay-dialog"
    max-width="765px"
    hide-header
    hide-footer
    persistent
  >
    <ctk-dialog-close-button
      class="tw-top-0 tw-right-0"
      data-test="close"
      @click.native="dialogValue = false"
    />

    <div class="tw-flex tw-flex-col md:tw-flex-row invoices-pay-dialog__body">
      <template
        v-if="isSubmitted"
      >
        <invoices-pay-dialog-payment-status
          v-if="isSuccessfull"
          :title="$t('billing.titles.pay.accepted')"
          :button-title="$t('close') | capitalize"
          success
          data-test="success"
          @click="dialogValue = false"
        >
          <i18n
            path="billing.paragraphs.pay.accepted"
            tag="p"
            data-test="content"
          >
            <template #email>
              <span
                class="tw-font-semibold tw-whitespace-nowrap"
                data-test="email"
                v-text="getUserInfos.email"
              />
            </template>
          </i18n>
        </invoices-pay-dialog-payment-status>
        <invoices-pay-dialog-payment-status
          v-else
          :title="$t('app.titles.pay.declined')"
          :button-title="$t('app.buttons.retry_payment')"
          data-test="error"
          @click="reset"
        >
          <p
            v-text="$t('app.paragraphs.pay.declined')"
            data-test="content"
          />
        </invoices-pay-dialog-payment-status>
      </template>
      <template
        v-else
      >
        <div class="invoices-pay-dialog__summary tw-relative tw-w-full tw-flex tw-flex-col tw-p-4 md:tw-p-8">
          <h2
            class="invoices-pay-dialog__summary__title tw-relative tw-font-medium tw-text-2xl tw-leading-none tw-mb-10 tw-pr-6 md:tw-pr-0"
            data-test="title"
            v-text="$t('app.titles.pay')"
          />

          <div
            class="md:tw-mb-10"
          >
            <div
              v-text="$t('app.labels.pay_amount')"
              class="tw-text-gray-700"
              data-test="label-amount"
            />
            <div
              v-if="getInvoicesTotal"
              v-text="$t('price_incl_tax', {
                price: $options.filters.currency(getInvoicesTotal.amount, getInvoicesTotal.currency, $i18n.locale)
              })"
              class="tw-text-blue-500 tw-text-2xl"
              data-test="value-amount"
            />
          </div>
        </div>

        <div class="invoices-pay-dialog__form tw-w-full tw-p-4 md:tw-p-8 md:tw-pt-24">
          <shipment-payment-form
            v-if="getInvoicesTotal"
            ref="payment-form"
            :stripe="stripe"
            :button-label="'app.buttons.pay_amount'"
            :amount="getInvoicesTotal.amount"
            :currency="getInvoicesTotal.currency"
            :loader="'paying invoices'"
            :expired="false"
            data-test="payment-form"
            @submitted="submitted"
          />
        </div>
      </template>
    </div>
  </ctk-dialog>
</template>

<script>
  import { ref, watch } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import useStripe from '@/composables/useStripe'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import { isTesting } from '@/../utils/env'
  import { EventBus } from '@/services/EventBus'
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import InvoicesPayDialogPaymentStatus from './_subs/InvoicesPayDialogPaymentStatus/index.vue'
  import ShipmentPaymentForm from '@/views/Shippers/components/ShipmentPaymentView/_subs/ShipmentPaymentForm/index.vue'

  /**
   * @module component - InvoicesPayDialog
   */
  export default {
    name: 'InvoicesPayDialog',
    props: {
      value: {
        type: Boolean,
        default: true
      }
    },
    components: {
      CtkDialog,
      CtkDialogCloseButton,
      InvoicesPayDialogPaymentStatus,
      ShipmentPaymentForm
    },
    data () {
      return {
        currentTab: 0,
        formData: {
          sourceId: null,
          cardHolder: null
        }
      }
    },
    setup (props) {
      const { stripe } = useStripe()
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      const isSubmitted = ref(false)
      const isSuccessfull = ref(false)

      function reset () {
        isSubmitted.value = false
        isSuccessfull.value = false
      }

      watch(dialogValue, (value) => {
        if (!value) {
          EventBus.$emit('invoices:refresh')
        }

        reset()
      })

      return {
        stripe,
        dialogValue,
        isSubmitted,
        isSuccessfull,
        reset
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      ...mapGetters('billing/credit-card-payment', [
        'getInvoicesTotal',
        'getPaymentIntent'
      ])
    },
    methods: {
      ...mapActions('billing/credit-card-payment', [
        'unselectAll'
      ]),
      /**
       * Called whenever the form is submitted
       * @function submitted
       * @param {object} params
       * @param {string} params.cardHolder
       * @param {string?} params.sourceId
       * @param {boolean} params.saveCard
       * @param {any?} params.cardElement
       */
      submitted (params) {
        /**
         * Since during test mode, we cannot pass through Stripe, we must ignore
         * the integration.
         */
        if (isTesting) {
          this.isSuccessfull = true
          this.isSubmitted = true
        } else {
          this.$wait.start('paying invoices')

          let paymentMethod
          if (params.sourceId) {
            paymentMethod = params.sourceId
          } else {
            paymentMethod = {
              card: params.cardElement,
              billing_details: {
                name: params.cardHolder
              }
            }
          }

          this.stripe.confirmCardPayment(this.getPaymentIntent, {
            payment_method: paymentMethod,
            setup_future_usage: params.saveCard ? 'off_session' : null
          })
            .then(res => {
              if (res.error && res.error.type === 'validation_error') return

              this.isSuccessfull = !res.error
              this.isSubmitted = true

              if (this.isSuccessfull) this.unselectAll()
            })
            .finally(() => {
              this.$wait.end('paying invoices')
            })
        }
      }
    }
  }
</script>

<style lang="scss">

  .invoices-pay-dialog {
    @media only screen and (min-width: $breakpoint-tablet) {
      &__summary {
        width: 45%;
        padding-bottom: 170px;
      }

      &__form {
        width: 55%;
      }
    }

    &__summary {
      background-color: $light-gray;
      border-radius: 0.3rem;

      &__title {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.75rem;
          width: 190px;
          height: 1px;
          background-color: $secondary-text;
        }
      }

      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 16px;
        height: 110px;
        width: 100%;
        background-image: url('~@/assets/img/illustrations/form-sidebar.svg');
        background-size: cover;
        background-position: center bottom;
        z-index: 0;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__summary {
        &::after {
          display: none;
        }
      }
    }
  }

  .invoices-pay-dialog .modal-wrapper {
    justify-content: unset;
    align-items: unset;
  }

  .invoices-pay-dialog .modal-container {
    max-height: initial;
    margin: auto !important;
    width: 100%;
  }

</style>
