<template>
  <payment-detail-total
    :total="total"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  import PaymentDetailTotal from '@/views/Common/Billing/_subs/Payments/components/PaymentDetail/_subs/PaymentDetailTotal/index.vue'

  /**
   * @module component - CarrierPaymentDetailTotal
   */
  export default {
    name: 'CarrierPaymentDetailTotal',
    components: {
      PaymentDetailTotal
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentPayment'
      ]),
      /**
       * Returns the formatted total value
       * @function total
       * @returns {string} total
       */
      total () {
        const payment = this.getCurrentPayment
        return payment
          ? this.$options.filters
            .currency(payment.amount, payment.currency, this.$i18n.locale)
          : null
      }
    }
  }
</script>
