<template>
  <div class="billing">
    <h2
      v-text="$t('account.billing.title')"
      class="billing__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900"
    />

    <form
      class="billing__form tw-w-full"
      :disabled="hasPendingRequest"
      @submit.prevent="submitted"
    >
      <ValidationObserver
        ref="observer"
      >
        <template slot-scope="{ dirty }">
          <ValidationProvider
            ref="email-provider"
            :name="$t('account.billing.fields.email')"
            rules="email"
            slim
          >
            <template
              slot-scope="{ invalid, validated, errors }"
            >
              <ctk-input-text
                id="email"
                v-model="formData.email"
                :label="$t('account.billing.fields.email') | capitalize"
                :disabled="hasPendingRequest"
                :error="invalid && validated"
                :hint="errors[0]"
                :loader="$wait.is('fetching company')"
                data-test="billing-email-field"
                type="email"
                name="email"
                class="tw-mb-3"
                autocomplete="email"
                clearable
              />
            </template>
          </ValidationProvider>

          <!-- Address -->
          <fieldset
            class="billing__form__address pt-2"
          >
            <legend class="tw-hidden">
              {{ $t('account.billing.titles.address') }}
            </legend>

            <ctk-input-text
              id="street_name"
              v-model="formData.address.street_name"
              :label="$t('account.billing.fields.street_name') | capitalize"
              :disabled="true"
              :loader="$wait.is('fetching company')"
              data-test="street-field"
              type="text"
              name="street_name"
              class="tw-mb-2"
              readonly
              required
            />

            <div class="billing__form__address__aside tw-flex tw-flex-col 2sm:tw-flex-row tw-mb-2">
              <ctk-input-text
                id="postal_code"
                v-model="formData.address.postal_code"
                :label="$t('app.labels.postal_code')"
                :disabled="true"
                :loader="$wait.is('fetching company')"
                data-test="postal-code-field"
                type="text"
                name="postal_code"
                class="tw-w-full 2sm:tw-w-1/3 2sm:tw-mr-2 tw-mb-2 2sm:tw-mb-0"
                readonly
                required
              />
              <ctk-input-text
                id="city"
                v-model="formData.address.city"
                :label="$t('app.labels.city')"
                :disabled="true"
                :loader="$wait.is('fetching company')"
                data-test="city-field"
                type="text"
                name="city"
                class="tw-w-full 2sm:tw-w-1/3 2sm:tw-mr-2 tw-mb-2 2sm:tw-mb-0"
                readonly
                required
              />

              <country-selector
                id="country"
                v-model="formData.address.country"
                :label="$t('app.labels.country')"
                :disabled="true"
                :items="countries"
                :data-vv-as="$t('app.fields.country')"
                :loader="$wait.is('fetching company')"
                :preferred-countries="['FR', 'BE', 'DE', 'NL', 'ES', 'IT', 'PL', 'LU', 'MC', 'AD']"
                data-test="country-field"
                color="#287696"
                has-name
                type="text"
                name="country"
                class="2sm:tw-w-1/3"
                readonly
                required
              />
            </div>
          </fieldset>

          <p
            class="billing__form__address-explanation tw-italic tw-leading-tight"
            data-test="address-explanation"
          >
            {{ $t('account.billing.paragraphs.address') }}
          </p>

          <div class="billing__form__buttons tw-flex tw-justify-between tw-mt-10">
            <ui-button
              :disabled="!dirty || hasPendingRequest"
              variant="link"
              type="button"
              data-test="cancel-button"
              @click="reset"
            >
              {{ $t('account.buttons.cancel') }}
            </ui-button>
            <ui-button
              :loading="$wait.is('updating billing infos')"
              :disabled="hasPendingRequest"
              class="billing__form__buttons__save tw-mr-2"
              variant="primary"
              type="submit"
              data-test="save-button"
            >
              {{ $t('account.buttons.save') }}
            </ui-button>
          </div>
        </template>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import CountrySelector from '@/components/CtkPhoneNumber/_subs/CountrySelector'
  import { countries } from '@/components/CtkPhoneNumber/assets/js/phoneCodeCountries.js'
  import { showToaster } from '@/services/Toaster'
  import store from '@/store'
  import { Company } from '@/resources'
  import CtkInputText from '@/components/CtkInputs/CtkInputText'

  /**
   * @module view - billing
   */
  export default {
    name: 'Billing',
    components: {
      CtkInputText,
      CountrySelector
    },
    metaInfo () {
      return {
        title: this.$t('account.billing.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)

      next()
    },
    data () {
      return {
        tempEmail: null,
        address: {},
        formData: {
          email: null,
          address: {
            street_name: null,
            postal_code: null,
            city: null,
            country: null
          }
        }
      }
    },
    mounted () {
      this.reset()

      /**
       * Fetch company informations
       * (do ignore if we're in a test environment; causing http issues
       * during the unit tests)
       */
      if (process.env.NODE_ENV !== 'test') {
        this.fetchCompany()
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      countries () {
        return countries
      },
      /**
       * Returns true if there are any pending requests
       * @function hasPendingRequest
       * @returns {boolean}
       */
      hasPendingRequest () {
        return this.$wait.is('updating billing infos') || this.$wait.is('fetching company')
      }
    },
    methods: {
      ...mapActions('auth', [
        'retrieveCompany'
      ]),
      /**
       * Fetch the company informations
       * @function fetchCompany
       */
      fetchCompany () {
        this.$wait.start('fetching company')
        this.retrieveCompany()
          .then(({ data }) => {
            const { billing } = data
            this.formData.email = billing.email
            this.tempEmail = billing.email

            if (billing.address) {
              this.address = {
                street_name: billing.address.street_name,
                city: billing.address.city,
                postal_code: billing.address.postal_code,
                country: billing.address.country
              }
              this.formData.address = Object.assign({}, this.address)
            }
          })
          .catch(() => {})
          .finally(() => {
            this.$wait.end('fetching company')
          })
      },
      submitted () {
        if (this.$wait.is('updating billing infos')) {
          return
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) {
              return
            }

            /**
             * Update or delete the company email
             */
            let emailRequest
            if (this.formData.email) {
              emailRequest = Company.billingEmail({
                cid: this.getUserInfos.companies[0].uuid
              }, {
                email: this.formData.email
              })
            } else {
              emailRequest = Company.deleteBillingEmail({
                cid: this.getUserInfos.companies[0].uuid
              })
            }

            this.$wait.start('updating billing infos')
            Promise.all([
              emailRequest
            ])
              .then(() => {
                showToaster(
                  this,
                  this.$t('account.billing.paragraphs.updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
              })
              .catch((err) => {
                if (!err.response) return

                /**
                 * TODO: Handle missing violations
                 */
                showToaster(
                  this,
                  this.$t('account.paragraphs.generic_error'), {
                    type: 'error',
                    position: 'bottom-right'
                  }
                )
              })
              .finally(() => this.$wait.end('updating billing infos'))
          })
      },
      /**
       * Set the fields information according to the current user informations
       * @function reset
       */
      reset () {
        this.formData = {
          email: this.tempEmail,
          address: Object.assign({
            street_name: null,
            city: null,
            postal_code: null,
            country: null
          }, this.address)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .billing {
    &__title {
      @media only screen and (max-width: 857px) {
        display: none;
      }
    }
  }

</style>
