<template>
  <div class="tw-flex billing-table-head">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'BillingTableHead'
  }
</script>
