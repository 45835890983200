var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-detail tw-flex tw-flex-col tw-flex-1"},[_c('invoice-detail-header',{staticClass:"flex-fixed",attrs:{"invoice":_vm.getCurrentInvoice}}),_c('div',{staticClass:"invoice-detail-content tw-flex-1 tw-flex tw-flex-col"},[(!_vm.$wait.is('loading invoice') && !_vm.$wait.is('loading invoice billing') && _vm.getCurrentInvoice && !_vm.$err.has('no invoice'))?_c('div',{staticClass:"p-3"},[_c('pricing-invoice',{attrs:{"invoice":_vm.getCurrentInvoice,"currency":_vm.getCurrentInvoice.currency}}),(_vm.isUserShipper
          ? (_vm.getCurrentInvoice.shipment && _vm.getCurrentInvoice.shipment.billing)
          : (_vm.getCurrentInvoice.mission && _vm.getCurrentInvoice.mission.billing))?_c('invoice-detail-invoices'):_vm._e(),(_vm.getCurrentInvoice.shipment || _vm.getCurrentInvoice.mission)?[_c(_vm.invoiceDetailTotalComponent,{tag:"component"}),(_vm.isUserShipper)?[_c('ui-button',{staticClass:"mt-3 tw-w-full",attrs:{"to":{
              name: 'Shipment',
              params: {
                uuid: _vm.getCurrentInvoice.shipment.uuid
              }
            },"rounded":false,"variant":"info","outline":""}},[_vm._v(" "+_vm._s(_vm.$t('billing.buttons.view_shipment'))+" ")])]:[_c('ui-button',{staticClass:"mt-3 tw-w-full",attrs:{"to":{
              name: 'Mission',
              params: {
                uuid: _vm.getCurrentInvoice.mission.uuid
              }
            },"variant":"info"}},[_vm._v(" "+_vm._s(_vm.$t('billing.buttons.view_mission'))+" ")])]]:_vm._e()],2):_vm._e(),(_vm.$wait.is('loading invoice'))?_c('ctk-loading-layer',[_vm._v(" "+_vm._s(_vm.$t('billing.loading_invoice_detail'))+" ")]):_vm._e(),(_vm.$err.has('no invoice') && !_vm.getCurrentInvoice && !_vm.$wait.is('loading invoice'))?_c('div',{staticClass:"tw-flex-1 invoice-detail__error"},[_c('ctk-empty-state',{attrs:{"title":_vm.$t('billing.title.no_invoice'),"sidebar":""}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }