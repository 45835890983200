<template>
  <div class="tw-rounded invoice-detail-total">
    <slot />
    <!-- <div
      v-if="mission"
      class="p-3 pb-2 invoice-detail-total__mission"
    >
      <div class="tw-flex">
        <div class="tw-flex-1 invoice-detail-total__mission__label">
          {{ $t('billing.invoice_detail.mission') }}
        </div>
        <div class="tw-flex tw-flex-col invoice-detail-total__mission__value">
          <div class="text-right">
            {{ mission.reference }}
          </div>
          <div
            v-if="mission.pickup && mission.delivery"
            class="fs-18 tw-font-medium text-right"
          >
            {{ mission.pickup.address.postal_code }} > {{ mission.delivery.address.postal_code }}
          </div>
        </div>
      </div>
      <ui-button
        :to="{
          name: 'Mission',
          params: {
            uuid: mission.uuid
          }
        }"
        class="mt-3 tw-w-full invoice-detail-total__mission__button"
        outline
        :rounded="false"
      >
        {{ $t('billing.buttons.view_mission') }}
      </ui-button>
    </div> -->

    <!-- <hr class="m-0"> -->

    <!-- <div
      v-if="billing"
      class="p-3 pt-2 tw-flex invoice-detail-total__amount"
    >
      <div class="tw-flex-1 invoice-detail-total__amount__label">
        {{ $t('billing.invoice_detail.mission_amount') }}
      </div>
      <div
        v-if="billing.invoices_amount && billing.invoices_vat_included_amount"
        class="tw-flex tw-flex-col invoice-detail-total__amount__value"
      >
        <div class="text-right">
          {{ $t('price_excl_tax', {
            price: $options.filters.currency(billing.invoices_amount, getCurrentInvoice.currency, $i18n.locale)
          }) }}
        </div>
        <div class="fs-18 tw-font-medium text-right">
          {{ $t('price_incl_tax', {
            price: $options.filters.currency(billing.invoices_vat_included_amount, getCurrentInvoice.currency, $i18n.locale)
          }) }}
        </div>
      </div>
      <div
        v-else
      >
        {{ $t('price_excl_tax', {
          price: $options.filters.currency(billing.price, getCurrentInvoice.currency, $i18n.locale)
        }) }}
      </div>
    </div> -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * @module component - invoiceDetailTotal
   */
  export default {
    name: 'InvoiceDetailTotal',
    computed: {
      ...mapGetters('billing', [
        'getCurrentInvoice'
      ]),
      mission () {
        return this.getCurrentInvoice.mission
      },
      billing () {
        return this.mission.billing
      }
    }
  }
</script>

<style lang="scss" scoped>

  .invoice-detail-total {
    position: relative;
    background-color: $info;
    color: white;

    &__mission__label,
    &__amount__label {
      margin-top: auto;
    }

    hr {
      border-top: 1px solid rgba(white, 0.2);
    }

    &::before {
      position: absolute;
      content: ' ';
      top: -8px;
      width: 0;
      height: 0;
      left: 8px;
      border-style: solid;
      border-width: 0 10px 8px 10px;
      border-color: transparent transparent $info transparent;
    }
  }

</style>
